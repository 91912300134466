import { UseFormReturn } from 'react-hook-form'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from 'src/shared/components/ui/form'

import { Checkbox } from '../ui'

export const FormCheckbox = ({
  name,
  label,
  description,
  methods,
  disabled,
  className = '',
}: {
  name: string
  label: string
  description?: string
  methods: UseFormReturn<any>
  disabled?: boolean
  className?: string
}) => {
  return (
    <FormField
      control={methods.control}
      name={name}
      render={() => (
        <FormItem className={className}>
          <FormControl>
            <Checkbox
              checked={methods.watch(name)}
              setChecked={(checked) => methods.setValue(name, checked)}
              disabled={disabled}
              value={name}
              label={label}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
