import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import * as React from 'react'

import { cn } from './utils'

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    label: string
    checked: boolean
    setChecked: (checked: boolean) => void
  }
>(({ className, id, label, checked, setChecked, ...props }, ref) => {
  return (
    <div className="items-top flex space-x-2">
      <CheckboxPrimitive.Root
        ref={ref}
        id={id}
        className={cn(
          'peer h-4 w-4 shrink-0 rounded-sm border border-[#a4a4a4] ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          checked ? 'bg-green-400 border-none text-primary-foreground' : '',
          className
        )}
        checked={checked}
        onCheckedChange={setChecked}
        {...props}
      />

      <div className="grid gap-1.5 leading-none">
        {label && (
          <label
            htmlFor={id}
            className="text-sm  leading-none cursor-pointer"
            onClick={() => setChecked(!checked)}
          >
            {label}
          </label>
        )}
      </div>
    </div>
  )
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
