import { motion } from 'framer-motion'
import React, { createContext, useContext } from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'src/shared/components/ui/utils'

interface Links {
  label: React.ReactNode
  href: string
  icon?: React.JSX.Element | React.ReactNode
  external?: boolean
}

interface SidebarContextProps {
  animate: boolean
}

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined)

export const useSidebar = () => {
  const context = useContext(SidebarContext)

  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }

  return context
}

export const SidebarProvider = ({
  children,
  animate = true,
}: {
  children: React.ReactNode
  open?: boolean
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  animate?: boolean
}) => {
  return (
    <SidebarContext.Provider value={{ animate: animate }}>
      {children}
    </SidebarContext.Provider>
  )
}

export const Sidebar = ({
  children,
  animate,
}: {
  children: React.ReactNode
  animate?: boolean
}) => {
  return <SidebarProvider animate={animate}>{children}</SidebarProvider>
}

export const SidebarBody = (props: React.ComponentProps<typeof motion.div>) => {
  return (
    <>
      <DesktopSidebar {...props} />
    </>
  )
}

const DesktopSidebar = ({
  className,
  children,
  ...props
}: React.ComponentProps<typeof motion.div>) => {
  return (
    <>
      <motion.div
        className={cn(
          'p-3 m-3 w-[220px] rounded-xl hidden md:flex md:flex-col flex-shrink-0 glass',
          className
        )}
        {...props}
      >
        {children}
      </motion.div>
    </>
  )
}

export const SidebarLink = ({
  link,
  className,
  isActive,
  ...props
}: {
  link: Links
  className?: string
  isActive?: boolean
  props?: any
  external?: boolean
}) => {
  return (
    <p
      className={cn(
        'flex items-center justify-start gap-2  group/sidebar py-1',
        isActive ? 'text-white' : 'inherit',
        className
      )}
      {...props}
    >
      {link.icon}

      <motion.span
        animate={{
          display: 'inline-block',
          opacity: 1,
        }}
        className={cn(
          'text-sm font-light  whitespace-pre inline-block !p-0 !m-0 '
        )}
        //group-hover/sidebar:translate-x-1 transition duration-150 cursor-default
      >
        {link.label}
      </motion.span>
    </p>
  )
}

export const SidebarLinkChildren = ({
  link,
  className,
  ...props
}: {
  link: Links
  className?: string
  props?: any
}) => {
  return (
    <Link
      className={cn(
        'flex items-center justify-start gap-2 group/sidebar',
        className
      )}
      to={link.href}
      {...props}
    >
      {link.icon}

      <motion.span
        animate={{
          display: 'inline-block',
          opacity: 1,
        }}
        className="text-xs font-light group-hover/sidebar:translate-x-1 transition duration-150 whitespace-pre inline-block !p-0 !m-0 cursor-default"
      >
        {link.label}
      </motion.span>
    </Link>
  )
}
