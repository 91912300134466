import { ArrowRight } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { Tool_ } from 'src/api/db/apiTools'
import { Receipt_ } from 'src/api/db/useReceiptsApi'
import { paths } from 'src/routes/paths'
import { Row } from 'src/shared/components/Semantic/all'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  Button,
  Image,
} from 'src/shared/components/ui'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'
import { cn } from 'src/shared/components/ui/utils'

import { EditReceipt } from './EditSubscription'
import { EmailTabsContent } from './EmailTabsContent'
import { ReceiptRow } from './ReceiptRow'

export const Receipt = ({
  receipt,
}: {
  receipt: Receipt_ & {
    sender?: {
      tool: Tool_[] | null
    }
  }
}) => {
  const navigate = useNavigate()

  return (
    <Accordion
      type="single"
      collapsible
      id={receipt.id.toString()}
      className="glass p-3 rounded-xl"
    >
      <AccordionItem value="item-1">
        <ReceiptRow receipt={receipt as any} />

        <AccordionContent className="">
          <div className="mt-3 p-2 rounded-xl">
            <Tabs defaultValue="cost">
              <Row>
                <TabsList>
                  <TabsTrigger value="cost">Cost info</TabsTrigger>
                  {receipt.source === 'gmail' && (
                    <TabsTrigger value="email">Email</TabsTrigger>
                  )}
                </TabsList>

                {receipt.sender?.tool && receipt.sender?.tool?.length > 0 && (
                  <Button
                    variant="link"
                    className="ml-auto"
                    onClick={() =>
                      navigate(
                        paths.software_table +
                          '/' +
                          receipt.sender?.tool?.[0].id
                      )
                    }
                  >
                    Go to tool
                    <ArrowRight className="w-4 h-4 ml-1" />
                  </Button>
                )}
              </Row>

              <TabsContent value="cost">
                <div className={cn('mt-1 grid grid-cols-3 gap-3')}>
                  {receipt.receipt_file && (
                    <Image
                      className="border rounded-2xl col-span-2 opacity-90"
                      src={receipt.receipt_file || ''}
                      alt="Receipt"
                    />
                  )}

                  <EditReceipt receipt_id={receipt.id} />
                </div>
              </TabsContent>

              <TabsContent value="email">
                <EmailTabsContent receipt={receipt as any} />
              </TabsContent>
            </Tabs>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
