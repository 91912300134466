import { ChartColumn, CreditCard } from 'lucide-react'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { Column, Row } from 'src/shared/components/Semantic/all'

import { StatCard } from '../Software_Insights/components/StatCard'
import { AreaChartComponent } from './AreaChart'
import { BarChartComponent } from './BarChart'

export const CostsPage = () => {
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({
    organization_id: orgId || '',
  })

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-2 items-center mb-1 py-2">
        <ChartColumn />
        <h1 className="text-2xl ">Costs</h1>
      </Row>

      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-2">
          <Column className="gap-4">
            <StatCard
              title="Annual spend"
              value={`$${Math.round(tools?.calculated?.annualSpend || 0)}`}
              icon={<CreditCard className="w-6 h-6 text-muted-foreground" />}
              description="Average annual spend"
            />

            <StatCard
              title="Monthly spend"
              value={`$${Math.round(tools?.calculated?.monthlySpend || 0)}`}
              icon={<CreditCard className="w-6 h-6 text-muted-foreground" />}
              description="Average monthly spend"
            />
          </Column>
        </div>

        <div className="col-span-4">
          <BarChartComponent />
        </div>
      </div>

      <AreaChartComponent />
    </Column>
  )
}
