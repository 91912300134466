import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { queryKeys } from './queryKeys'

export type InsertSender = Database['public']['Tables']['sender']['Insert']
export type UpdateSender = Database['public']['Tables']['sender']['Update']
export type Sender_ = Database['public']['Tables']['sender']['Row']

export const useSendersApi = ({ orgId }: { orgId?: string }) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { data: senders } = useQuery({
    queryKey: [queryKeys.senders],
    queryFn: async () =>
      await supabase.from('sender').select('*').eq('organization_id', orgId!),
    enabled: !!orgId,
  })

  const updateSender = useMutation({
    mutationFn: async (updateSender: UpdateSender) =>
      await supabase
        .from('sender')
        .update(updateSender)
        .eq('id', updateSender.id!),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.vendors] })
      queryClient.invalidateQueries({ queryKey: [queryKeys.toolsAll] })

      toast({
        variant: 'success',
        title: 'Updated vendor',
      })
    },
  })

  return {
    senders,
    updateSender,
  }
}
