import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowRight, Chrome, Save, User } from 'lucide-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSupabaseClient } from 'src/api/supabase'
import { FormInput, FormWrapper } from 'src/shared/components/FormComponents/'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, CardTitle } from 'src/shared/components/ui'
import { z } from 'zod'

import { useOrgUsersApi } from '../../api/db/apiOrgUsers'
import { paths } from '../../routes/paths'

const formSchema = z.object({
  first_name: z.string().min(2),
  last_name: z.string().min(2),
  email: z.string().email(),
  phone: z.string().min(4),
})

export const UserSettingsPage = () => {
  const { orgUser, updateUser } = useOrgUsersApi()
  const navigate = useNavigate()
  const supabase = useSupabaseClient()

  const saved = JSON.parse(localStorage.getItem('onboardingState') || '{}')

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    },
  })

  useEffect(() => {
    const user_ = orgUser?.data
    if (user_) {
      methods.setValue('first_name', user_.user?.first_name || '')
      methods.setValue('last_name', user_.user?.last_name || '')
      methods.setValue('email', user_.user?.email || '')
      methods.setValue('phone', user_.user?.phone?.toString() || '')
    }
  }, [orgUser])

  const onSubmitUserSettings = (values: z.infer<typeof formSchema>) => {
    if (values.phone.length < 4) {
      methods.setError('phone', {
        message: 'Phone number must be at least 4 characters',
      })
      return
    }

    if (!values.phone.includes('+')) {
      methods.setError('phone', {
        message:
          'Phone number must include a country code. For example: +46701234567',
      })
      return
    }

    return updateUser.mutate({
      id: orgUser?.data?.user_id,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
    })
  }

  const handleLogout = async () => {
    await supabase.auth.signOut()
    navigate(paths.loginSignUp)
  }

  const UserSettings = () => (
    <Card className="w-full justify-center items-center">
      <CardTitle>Profile</CardTitle>

      <Column className="gap-2 w-full mt-5">
        <FormWrapper onSubmit={onSubmitUserSettings} methods={methods}>
          <FormInput name="first_name" label="First name" methods={methods} />
          <FormInput name="last_name" label="Last name" methods={methods} />
          <FormInput name="phone" label="Phone" methods={methods} />
          <FormInput name="email" label="Email" methods={methods} disabled />

          <Button
            variant={'outline'}
            style={{ marginTop: '1rem' }}
            type="submit"
          >
            <Save className="h-4 w-4 mr-2" />
            Save
          </Button>
        </FormWrapper>
      </Column>
    </Card>
  )

  const Actions = () => (
    <Card className="w-full justify-center items-center">
      <h2 className="text-xl  mb-2">Other</h2>

      <Row className="gap-4 w-full mt-5">
        <Button
          variant="secondary"
          className={saved.step1 ? '' : ''} // animate-pulse
          onClick={() =>
            window.open(
              'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
              '_blank'
            )
          }
        >
          Download extension
          <Chrome className="ml-2 h-4 w-4" />
        </Button>

        <Button onClick={handleLogout} variant="light">
          Log out
          <ArrowRight className="h-4 w-4 ml-2" />
        </Button>
      </Row>
    </Card>
  )

  return (
    <Column className="gap-4 w-full h-full">
      <Row className="gap-2 items-center mb-1 py-1">
        <User />
        <h1 className="text-2xl ">My account</h1>
      </Row>

      {UserSettings()}
      {Actions()}
    </Column>
  )
}
