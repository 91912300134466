import LogRocket from 'logrocket'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { Roles } from 'src/shared/consts'

import { useAuth } from '../../shared/hooks/authProvider'
import { paths } from '../paths'

export const ProtectedRoute = ({ component, ...args }: any) => {
  const { loading } = useAuth()
  const navigate = useNavigate()
  const { userAllOrgs } = useOrgUsersApi()
  const { session } = useAuth()

  useEffect(() => {
    const userId = session?.user?.id

    // If user is not logged in, redirect to login page
    if (!loading && !userId) navigate(paths.loginSignUp)

    // If user is only extension user, redirect to extension page
    if (userAllOrgs?.data) {
      const isAdminOrSuperadmin = userAllOrgs?.data?.some(({ role_id }) =>
        [Roles.ADMIN, Roles.SUPERADMIN].includes(role_id)
      )
      const isOnlyExtensionUser = !isAdminOrSuperadmin
      if (isOnlyExtensionUser) navigate(paths.extension)
    }

    // If user is logged in, identify them in LogRocket
    if (userId && process.env.NODE_ENV === 'production') {
      LogRocket.identify(userId, {
        email: session?.user?.email || '',
      })
    }
  }, [session?.user?.id, userAllOrgs?.data])

  const Component = component
  return <Component {...args} />
}
