import { useGoogleLogin } from '@react-oauth/google'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import { CalendarIcon, MailPlus, RefreshCcw, Trash } from 'lucide-react'
import { useState } from 'react'
import { useEmailAccountsApi } from 'src/api/db/apiEmailAccounts'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { queryKeys } from 'src/api/db/queryKeys'
import { useScanEmailAccount } from 'src/api/server/scanEmailAccount'
import { ServerRoutes } from 'src/api/server/serverRoutes'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import { Calendar } from 'src/shared/components/ui/calendar'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/shared/components/ui/dialog'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/shared/components/ui/popover'
import { cn } from 'src/shared/components/ui/utils'
import { useAuth } from 'src/shared/hooks/authProvider'

export const AccountsDialog = ({
  dialogOpen,
  setDialogOpen,
}: {
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
}) => {
  const { session } = useAuth()
  const { emailAccounts, deleteEmailAccount } = useEmailAccountsApi({
    email: session?.user?.email,
  })
  const { orgId } = useOrgIdApi()
  const { orgUser } = useOrgUsersApi()
  const scanEmailAccount = useScanEmailAccount()

  const [loading, setLoading] = useState(false)

  const [date_from, setDateFrom] = useState<Date | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  )
  const [date_to, setDateTo] = useState<Date | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  )

  const queryClient = useQueryClient()

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/tasks.readonly https://www.googleapis.com/auth/tasks',
    onSuccess: async (codeResponse) => {
      console.log(codeResponse)
      setLoading(true)
      const tokens = await fetch(
        process.env.REACT_APP_BE_SERVER_URL + ServerRoutes.googleAuth,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: codeResponse.code,
            organization_id: orgId,
          }),
        }
      )

      if (tokens) {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.emailAccounts],
        })
        setLoading(false)
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  })

  const DatePicker = ({
    date,
    setDate,
    placeholder,
  }: {
    date: Date | undefined
    setDate: (date: Date | undefined) => void
    placeholder: string
  }) => (
    <Column className="gap-1">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="transparent"
            className={cn(
              'w-fit justify-start text-left ',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />

            {date ? format(date, 'yyyy/MM/dd') : <span>{placeholder}</span>}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            onSelect={(date) => setDate(date || new Date())}
            initialFocus
          />
        </PopoverContent>
      </Popover>

      <p className="text-xs text-muted-foregroun ml-2">
        {format(date!, 'MMM do, yyyy')}
      </p>
    </Column>
  )

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Accounts</DialogTitle>
        </DialogHeader>

        {!emailAccounts?.data?.length && (
          <p className="text-sm text-muted-foreground mb-2">
            Connect your accounts to scan for receipts in your inbox.
          </p>
        )}

        <Column className="gap-2 mt-2 ">
          {emailAccounts?.data?.map((emailAccount) => (
            <Row
              key={emailAccount.id}
              className="justify-between items-center glass p-3 rounded-lg border"
            >
              <Row className="gap-2">
                <DatePicker
                  date={date_from}
                  setDate={setDateFrom}
                  placeholder="From"
                />

                <DatePicker
                  date={date_to}
                  setDate={setDateTo}
                  placeholder="To"
                />
              </Row>

              <Row className="gap-2">
                <Column className="gap-1 ml-auto">
                  <Button
                    isLoading={scanEmailAccount.isLoading}
                    onClick={() => {
                      scanEmailAccount.mutate({
                        email: emailAccount.email || '',
                        organization_id: orgId,
                        org_user_id: orgUser?.data?.id!,
                        after: date_from ? format(date_from, 'yyyy/M/d') : '',
                        before: date_to ? format(date_to, 'yyyy/M/d') : '',
                      })

                      setDialogOpen(false)
                    }}
                  >
                    <RefreshCcw className="w-4 h-4 mr-2" />
                    {emailAccount.email}
                  </Button>

                  <p className="text-xs text-muted-foreground ml-2">
                    {emailAccount.last_scanned
                      ? `Scanned ${dayjs(emailAccount.last_scanned).fromNow()}`
                      : 'Not scanned'}
                  </p>
                </Column>

                <Button
                  isLoading={deleteEmailAccount.isLoading}
                  variant="ghost"
                  className="text-red-500"
                  onClick={() => deleteEmailAccount.mutate(emailAccount.id)}
                >
                  <Trash className="w-4 h-4" />
                </Button>
              </Row>
            </Row>
          ))}

          <Button
            isLoading={loading}
            variant="secondary"
            className="w-fit mt-4 mr-auto"
            onClick={googleLogin}
          >
            <MailPlus className="w-4 h-4 mr-2" />
            Add Gmail
          </Button>
        </Column>
      </DialogContent>
    </Dialog>
  )
}
