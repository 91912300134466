import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { UseFormReturn } from 'react-hook-form'
import { Button } from 'src/shared/components/ui/button'
import { Calendar } from 'src/shared/components/ui/calendar'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/shared/components/ui/form'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/shared/components/ui/popover'

import { cn } from '../ui/utils'

export const FormDatePicker = ({
  name,
  label,
  description,
  methods,
  placeholder = 'Pick a date',
  disabled = false,
  className = '',
  fullWidth = false,
}: {
  name: string
  label?: string
  description?: string
  methods: UseFormReturn<any>
  placeholder?: string
  disabled?: boolean
  className?: string
  fullWidth?: boolean
}) => {
  return (
    <FormField
      control={methods.control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel className="text-xs text-muted-foreground ">
            {label}
          </FormLabel>

          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant={'outline'}
                  className={cn(
                    'pl-3 text-left  rounded-md',
                    !field.value && 'text-muted-foreground',
                    fullWidth ? 'w-full' : 'w-[240px]'
                  )}
                  disabled={disabled}
                >
                  {field.value ? (
                    format(field.value, 'MMM do, yyyy')
                  ) : (
                    <span>{placeholder}</span>
                  )}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>

            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={(date) => field.onChange(date || new Date())}
                initialFocus
              />
            </PopoverContent>
          </Popover>

          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
