import dayjs from 'dayjs'
import {
  ArrowRight,
  Calendar,
  CreditCard,
  Mail,
  OctagonAlert,
  OctagonX,
} from 'lucide-react'
import { Tool_ } from 'src/api/db/apiTools'
import { Receipt_ } from 'src/api/db/useReceiptsApi'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Row } from 'src/shared/components/Semantic/all'
import { AccordionTrigger } from 'src/shared/components/ui'
import { cn } from 'src/shared/components/ui/utils'

import { getInfo } from './utils'

export const ReceiptRow = ({
  receipt,
}: {
  receipt: Receipt_ & { tool: Tool_ | null; sender: any }
}) => {
  const { cost } = getInfo(receipt)

  return (
    <AccordionTrigger className="p-0">
      <Row>
        <div className="grid grid-cols-12">
          <div className="col-span-3 flex items-center">
            {receipt.sender?.tool.length ? (
              <Row className="gap-2 items-center">
                <SaasIcon
                  toolName={receipt?.sender?.name || ''}
                  src={receipt.tool?.vendor?.logo_url || ''}
                  size="sm"
                />

                <p className="text-xs">{receipt?.sender?.name}</p>
              </Row>
            ) : (
              <Row className="gap-2 items-center text-xs">
                {receipt?.sender?.name}
              </Row>
            )}
          </div>

          <div className="col-span-3 flex items-center">
            <Calendar className="w-3 h-3 mr-2" />

            <Row className="gap-1 items-center text-xs">
              {dayjs(receipt.renewal_start_date).format('MMM DD, YYYY')}
              <ArrowRight className="w-3 h-3" />
              {dayjs(receipt.renewal_next_date).format('MMM DD, YYYY')}
            </Row>
          </div>

          <div className="col-span-2 flex items-center text-xs">
            {receipt.due_date && (
              <>
                <Row
                  className={cn(
                    ' gap-1 items-center',
                    dayjs(receipt.due_date).isAfter(new Date())
                      ? 'text-orange-600'
                      : 'text-gray-500'
                  )}
                >
                  <CreditCard className="w-3 h-3 mr-1" />

                  {dayjs(receipt.due_date).isAfter(new Date())
                    ? `Due in ${dayjs(receipt.due_date).diff(
                        dayjs(),
                        'days'
                      )} days`
                    : dayjs(receipt.due_date).format('MMM DD, YYYY')}
                </Row>

                <div className="col-span-1 flex items-start">
                  {receipt.warning_info &&
                    receipt.warning_info?.includes('late') && (
                      <OctagonX className="w-3 h-3 text-red-600 ml-2" />
                    )}

                  {receipt.warning_info &&
                    !receipt.warning_info?.includes('late') && (
                      <OctagonAlert className="w-3 h-3 text-orange-400 ml-2" />
                    )}
                </div>
              </>
            )}
          </div>

          <div className="col-span-2 flex items-center">
            <p className=" text-xs">{cost}</p>
          </div>

          <div className="col-span-2 flex items-center text-xs">
            <Mail className="w-3 h-3 mr-2" />

            {receipt.email_recipient}
          </div>
        </div>
      </Row>
    </AccordionTrigger>
  )
}
