import { zodResolver } from '@hookform/resolvers/zod'
import { Banknote, CalendarIcon } from 'lucide-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useReceiptsApi } from 'src/api/db/useReceiptsApi'
import {
  FormDatePicker,
  FormInput,
  FormSelect,
  FormWrapper,
} from 'src/shared/components/FormComponents'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import { Sheet, SheetContent } from 'src/shared/components/ui/sheet'
import { z } from 'zod'

import { pricingModelOptions, renewalFrequencyOptions } from './consts'

const formSchema = z.object({
  renewal_start_date: z.date(),
  renewal_next_date: z.date(),
  renewal_frequency: z.string(),
  pricing_model: z.string(),
  flat_fee_cost: z.number().optional().or(z.string()), // OR string? huh.
  usage_based_cost: z.number().optional().or(z.string()),
  price_per_seat: z.number().optional().or(z.string()),
  number_of_seats: z.number().optional().or(z.string()),
  other_cost: z.number().optional().or(z.string()),
})

export const EditSubscriptionSheet = ({
  isOpen,
  setIsOpen,
  receipt_id,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  receipt_id: number
}) => {
  const { receiptById, updateReceipt } = useReceiptsApi({
    receipt_id,
  })

  useEffect(() => {
    if (receiptById) {
      methods.setValue(
        'renewal_start_date',
        new Date(receiptById.renewal_start_date!)
      )
      methods.setValue(
        'renewal_frequency',
        receiptById.renewal_frequency || 'MONTHLY'
      )
      methods.setValue(
        'renewal_next_date',
        new Date(receiptById.renewal_next_date!)
      )
      methods.setValue('pricing_model', receiptById.pricing_model || 'FLAT_FEE')
      methods.setValue('flat_fee_cost', receiptById.flat_fee_cost || 0)
      methods.setValue('usage_based_cost', receiptById.usage_based_cost || 0)
      methods.setValue('price_per_seat', receiptById.price_per_seat || 0)
      methods.setValue('number_of_seats', receiptById.number_of_seats || 0)
      methods.setValue('other_cost', receiptById.other_cost || 0)
    }
  }, [receiptById])

  const handleSubmit = () => {
    console.log('SUBMITTING')
    const values = methods.getValues()
    updateReceipt.mutate({
      id: receipt_id,
      renewal_frequency: values.renewal_frequency,
      renewal_start_date: values.renewal_start_date.toISOString(),
      renewal_next_date: values.renewal_next_date.toISOString(),
      pricing_model: values.pricing_model,
      flat_fee_cost: Number(values.flat_fee_cost),
      usage_based_cost: Number(values.usage_based_cost),
      price_per_seat: Number(values.price_per_seat),
      number_of_seats: Number(values.number_of_seats),
      other_cost: Number(values.other_cost),
    })
    setIsOpen(false)
  }

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      renewal_frequency: 'MONTHLY',
      renewal_start_date: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ),
      renewal_next_date: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ),
      pricing_model: 'FLAT_FEE',
      flat_fee_cost: undefined,
      usage_based_cost: undefined,
      other_cost: undefined,
      price_per_seat: undefined,
      number_of_seats: undefined,
    },
  })

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent className="sm:max-w-[600px]">
        <Column className="gap-2 mt-4">
          <FormWrapper
            onSubmit={methods.handleSubmit(handleSubmit)}
            methods={methods}
          >
            {/* <Badge className="bg-purple-100 text-purple-800">Recurring</Badge> */}

            <Column className="gap-2">
              <Card>
                <Row className="gap-2 items-center mb-2">
                  <CalendarIcon className="w-4 h-4" />

                  <p className="text-sm ">Renewals</p>
                </Row>

                <FormSelect
                  name="renewal_frequency"
                  label="Renewal frequency"
                  options={renewalFrequencyOptions}
                  methods={methods}
                  className="w-full"
                />

                <Row className="gap-3">
                  <FormDatePicker
                    name="starts_at"
                    label="Start date"
                    methods={methods}
                    className="w-full"
                  />

                  <FormDatePicker
                    name="next_renewal_date"
                    label="Next renewal date"
                    methods={methods}
                    className="w-full"
                  />
                </Row>
              </Card>

              <Card>
                <Row className="gap-2 items-center mb-2">
                  <Banknote className="w-4 h-4" />
                  <p className="text-sm ">Cost</p>
                </Row>

                <Row className="gap-3">
                  <FormSelect
                    name="pricing_model"
                    label="Pricing model"
                    options={pricingModelOptions}
                    methods={methods}
                    className="w-full"
                  />

                  {methods.watch('pricing_model') === 'FLAT_FEE' && (
                    <FormInput
                      name="flat_fee_cost"
                      label="Price ($)"
                      methods={methods}
                      className="w-full"
                      type="number"
                    />
                  )}
                </Row>

                {methods.watch('pricing_model') === 'PER_SEAT' && (
                  <Row className="gap-3">
                    <FormInput
                      name="number_of_seats"
                      label="Number of seats"
                      type="number"
                      methods={methods}
                      className="w-full"
                    />

                    <FormInput
                      name="price_per_seat"
                      label="Price per seat ($)"
                      methods={methods}
                      className="w-full"
                      type="number"
                    />
                  </Row>
                )}

                {methods.watch('pricing_model') === 'USAGE_BASED' && (
                  <FormInput
                    name="usage_based_cost"
                    label="Usage cost ($)"
                    type="number"
                    methods={methods}
                    className="w-full"
                  />
                )}

                {methods.watch('pricing_model') === 'OTHER' && (
                  <FormInput
                    name="other_cost"
                    label="Other cost ($)"
                    type="number"
                    methods={methods}
                    className="w-full"
                  />
                )}
              </Card>
            </Column>

            <Row className="gap-2">
              <Button type="submit">Save</Button>

              <Button
                variant="light"
                onClick={() => {
                  setIsOpen(false)
                  methods.reset()
                }}
              >
                Cancel
              </Button>
            </Row>
          </FormWrapper>
        </Column>
      </SheetContent>
    </Sheet>
  )
}
