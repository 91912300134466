import dayjs from 'dayjs'
import { UserActivity_ } from 'src/api/db/apiUserActivity'

import { Receipt_ } from '../../../useReceiptsApi'
import { getInfo } from '../calculateUsageData/utils'
import {
  calculateAnnualPrice,
  calculateAnnualPriceFormatted,
  calculateMonthlyPrice,
  calculateMonthlyPriceFormatted,
  calculateQuarterlyPrice,
  calculateQuarterlyPriceFormatted,
} from './utils'

export const calculateData = ({
  receipts,
  user_activity,
}: {
  receipts: Receipt_[]
  user_activity: UserActivity_[]
}) => {
  const { userCount, monthlyUserCount, monthlyUserVisits } = getInfo(
    user_activity as any
  )

  const latestReceipt = receipts?.sort((a, b) =>
    b.email_received!.localeCompare(a.email_received!)
  )[0]

  const renewsIn = dayjs(latestReceipt?.date_of_invoice).diff(
    dayjs(),
    latestReceipt?.renewal_frequency === 'MONTHLY'
      ? 'month'
      : latestReceipt?.renewal_frequency === 'QUARTERLY'
      ? 'quarter'
      : 'year'
  )

  return {
    priceMonth: calculateMonthlyPrice(receipts),
    priceQuarter: calculateQuarterlyPrice(receipts),
    priceAnnual: calculateAnnualPrice(receipts),

    priceMonthFormatted: calculateMonthlyPriceFormatted(receipts),
    priceQuarterFormatted: calculateQuarterlyPriceFormatted(receipts),
    priceAnnualFormatted: calculateAnnualPriceFormatted(receipts),

    renewsIn,
    nextRenewalDate: dayjs(latestReceipt?.date_of_invoice).format('YYYY-MM-DD'),
    userCount,
    monthlyUserCount,
    monthlyUserVisits,
  }
}
