import { useParams } from 'react-router-dom'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { Card, CardContent, CardHeader } from 'src/shared/components/ui/card'
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from 'src/shared/components/ui/chart'

export const UsersBarChart = () => {
  const { tool_id } = useParams()
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tool = tools?.tools?.find((tool) => {
    return tool.id === Number(tool_id)
  })

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between p-0 mb-4">
        <p className="text-lg ">Monthly Users</p>
      </CardHeader>

      <CardContent>
        <ChartContainer
          config={{
            users: {
              label: 'Users',
              color: 'hsl(var(--chart-2))',
            },
          }}
          className="h-[300px] w-full"
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={tool?.calculated?.monthlyUserCount}>
              <XAxis
                dataKey="label"
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value}`}
              />
              <ChartTooltip content={<ChartTooltipContent hideLabel />} />
              <Bar
                dataKey="value"
                fill="var(--color-users)"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}
