import dayjs from 'dayjs'
import { Receipt_ } from 'src/api/db/useReceiptsApi'
import { Column } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'

export const EmailTabsContent = ({
  receipt,
}: {
  receipt: Receipt_ & { email_info: any }
}) => (
  <div className="grid grid-cols-5 gap-4 mt-1">
    <Card className="col-span-2 h-fit">
      <Column className="gap-2">
        <Column className="gap-2">
          <p className="text-sm text-muted-foreground ">
            <Badge variant="secondary" className="w-fit mr-2">
              Subject
            </Badge>
            {/* @ts-ignore */}
            {receipt?.email_info?.subject || ''}
          </p>
        </Column>

        <Column className="gap-2">
          <p className="text-sm text-muted-foreground ">
            <Badge variant="secondary" className="w-fit mr-2">
              Date
            </Badge>
            {receipt?.email_info?.date
              ? // @ts-ignore
                `${dayjs(receipt.email_info.date).format(
                  'MMM DD, YYYY'
                )} (${dayjs(receipt.email_info.date).format('DD/MM/YY')})`
              : ''}
          </p>
        </Column>

        <Column className="gap-2">
          <p className="text-sm text-muted-foreground ">
            <Badge variant="secondary" className="w-fit mr-2">
              From
            </Badge>
            {receipt?.email_info?.from || ''}
          </p>
        </Column>

        <Column className="gap-2">
          <p className="text-sm text-muted-foreground ">
            <Badge variant="secondary" className="w-fit mr-2">
              To
            </Badge>
            {receipt?.email_info?.to || ''}
          </p>
        </Column>
      </Column>
    </Card>

    <div className="w-full  p-4 rounded-xl col-span-3">
      <div
        dangerouslySetInnerHTML={{
          __html: receipt?.email_info?.htmlBody || '',
        }}
      ></div>
    </div>
  </div>
)
