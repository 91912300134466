import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { UserActivity_ } from '../../../apiUserActivity'
import { months } from '../consts'

dayjs.extend(relativeTime)

export const getInfo = (user_activities?: UserActivity_[]) => {
  const monthlyUserCount = months.map(({ month, label }) => {
    const users = user_activities
      ?.filter((activity) => dayjs(activity.last_visited).month() + 1 === month)
      ?.flat()
      .map((activity) => activity.org_user_id)

    return {
      label,
      value: new Set(users).size,
    }
  })

  const monthlyUserVisits = months
    .map(({ month }) => {
      const userActivitiesThisMonth = user_activities?.filter(
        (activity) => dayjs(activity.last_visited).month() + 1 === month
      )
      return userActivitiesThisMonth?.length || 0
    })
    .reduce((total, visits) => total + visits, 0)

  return {
    userCount: new Set(user_activities?.map((user) => user.org_user_id)).size,
    monthlyUserCount,
    monthlyUserVisits,
  }
}
