import { useOrgVendors } from '../apiOrgVendors'

export const useUntrackedTools = (orgId: string) => {
  const { orgVendors } = useOrgVendors()
  const untrackedTools = orgVendors?.data?.filter(
    (tool) => tool.status === 'not_in_stack'
  )

  const untrackedTools_ =
    untrackedTools?.filter((tool) => tool.status === 'not_in_stack')?.length ||
    0
  return untrackedTools_
}
