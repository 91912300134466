import {
  Building,
  CalendarSync,
  Cctv,
  ChartColumn,
  FlaskConical,
  ReceiptText,
  Table,
} from 'lucide-react'
import { useOrgVendors } from 'src/api/db/apiOrgVendors'
import { paths } from 'src/routes/paths'
import { Row } from 'src/shared/components/Semantic/all'

type Links = {
  label: React.ReactNode
  href: string
  icon: React.ReactNode
  children?: Links[]
}

export const useLinks = (): Links[] => {
  const { orgVendors } = useOrgVendors()

  return [
    {
      label: 'Receipts',
      href: paths.receipts,
      icon: <ReceiptText className="text-white/85 h-3 w-3 flex-shrink-0" />,
    },
    {
      label: 'Costs',
      href: paths.costs,
      icon: <ChartColumn className="text-white/85 h-3 w-3 flex-shrink-0" />,
    },
    {
      label: 'Software',
      href: paths.software_insights,
      icon: <CalendarSync className="text-white/85 h-3 w-3 flex-shrink-0" />,
      children: [
        {
          label: 'Insights',
          href: paths.software_insights,
          icon: (
            <FlaskConical className="text-white/85 h-3 w-3 flex-shrink-0" />
          ),
        },
        {
          label: 'Table',
          href: paths.software_table,
          icon: <Table className="text-white/85 h-3 w-3 flex-shrink-0" />,
        },
        {
          label: (
            <Row className="flex items-center gap-2 relative">
              <span>Detected</span>
              <p className="text-[10px] text-orange-500  bg-orange-50/10 rounded-full px-1 py-0.5 absolute right-[-27px]">
                {
                  orgVendors?.data?.filter((v) => v.status === 'not_in_stack')
                    .length
                }
              </p>
            </Row>
          ),
          href: paths.software_untracked,
          icon: <Cctv className="text-white/85 h-3 w-3 flex-shrink-0" />,
        },
      ],
    },
    {
      label: 'Organization',
      href: paths.organization,
      icon: <Building className="text-white/85 h-3 w-3 flex-shrink-0" />,
    },
  ]
}
