import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { Database } from 'supabase/database.types'

import { useOrgIdApi } from './apiOrgId'
import { queryKeys } from './queryKeys'

export type InsertOrgVendor =
  Database['public']['Tables']['org_vendor']['Insert']
export type UpdateOrgVendor =
  Database['public']['Tables']['org_vendor']['Update']
export type OrgVendor_ = Database['public']['Tables']['org_vendor']['Row']

export const useOrgVendors = () => {
  const { orgId: org_id } = useOrgIdApi()
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()

  const { data: orgVendors } = useQuery({
    queryKey: [queryKeys.org_vendor, org_id],
    queryFn: async () =>
      await supabase
        .from('org_vendor')
        .select('*')
        .eq('organization_id', org_id!),
    enabled: !!org_id,
  })

  const updateOrgVendor = useMutation({
    mutationFn: async (updateData: UpdateOrgVendor) => {
      const { data, error } = await supabase
        .from('org_vendor')
        .update(updateData)
        .eq('id', updateData.id!)
        .select()
        .single()

      if (error) throw error
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.org_vendor, org_id],
      })
    },
  })

  return {
    orgVendors,
    updateOrgVendor,
  }
}
