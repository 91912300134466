import dayjs from 'dayjs'
import { useReceiptsApi } from 'src/api/db/useReceiptsApi'

export const useChartData = () => {
  const { receiptsByOrg } = useReceiptsApi({})

  const chartData: { date: string; cost: number }[] = []
  const today = new Date()

  for (let i = 89; i >= 0; i--) {
    const date = new Date(today)
    date.setDate(date.getDate() - i)
    const date_ = dayjs(date).format('YYYY-MM-DD')

    let total_cost = 0

    receiptsByOrg?.data?.forEach((receipt) => {
      if (dayjs(receipt.date_of_invoice).format('YYYY-MM-DD') === date_) {
        total_cost += receipt.total_cost || 0
      }
    })

    chartData.push({
      date: date_,
      cost: total_cost,
    })
  }

  return chartData
}
