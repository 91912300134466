import * as React from 'react'

import { Label } from './label'
import { cn } from './utils'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  description?: string
  startContent?: React.ReactNode
  endContent?: React.ReactNode
  appendEndContent?: React.ReactNode
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      label,
      description,
      startContent,
      endContent,
      appendEndContent,
      ...props
    },
    ref
  ) => {
    return (
      <div className="relative w-full">
        {label && <Label htmlFor="email">{label}</Label>}

        <div className="w-full">
          <input
            type={type === 'number' ? 'number' : type}
            className={cn(
              'flex h-10 w-full rounded-full bg-background px-3 py-2 pl-4 text-xs ring-offset-background shadow-[inset_0_2px_2px_rgba(0,0,0,0.03)] file:border-0 file:bg-transparent file:text-sm file: file:text-foreground placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
              className
            )}
            ref={ref}
            {...props}
          />
          {startContent && (
            <div className="absolute inset-y-0 left-3 flex items-center">
              {startContent}
            </div>
          )}
          {endContent && (
            <div className="absolute inset-y-0 right-3 flex items-center">
              {endContent}
            </div>
          )}
          {appendEndContent && (
            <div className="absolute inset-y-0 left-8 flex items-center">
              {appendEndContent}
            </div>
          )}
        </div>

        {description && (
          <p className="text-sm text-muted-foreground">{description}</p>
        )}
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
