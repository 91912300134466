export enum Roles {
  SUPERADMIN = 1,
  ADMIN = 2,
  EXTENSION_USER = 3,
}

export const roleMap = {
  1: 'Org. owner',
  2: 'Admin',
  3: 'Extension user',
}

export const departmentOptions = [
  { value: 'IT', label: 'IT' },
  { value: 'Product', label: 'Product' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'HR', label: 'HR' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Data & Analytics', label: 'Data & Analytics' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Customer Success', label: 'Customer Success' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Other', label: 'Other' },
]

const subscriptionMap = {
  early_bird: { name: 'VIP', team_limit: 1000 },
  earlybird2024: { name: 'VIP', team_limit: 1000 },
  prod_RDSPAAJNzNdbZq: { name: 'Starter', team_limit: 10 },
  prod_RDSPOHUSjSuwWH: { name: 'Medium', team_limit: 30 },
  prod_RDSPRuknw3khBA: { name: 'Large', team_limit: 50 },
}

export const stripeProductIds = {
  starter: 'prod_RDSPAAJNzNdbZq',
  medium: 'prod_RDSPOHUSjSuwWH',
  large: 'prod_RDSPRuknw3khBA',
}

export const getSubscriptionName = (stripeProductId?: string | null) => {
  return stripeProductId ? subscriptionMap[stripeProductId]?.name : '-'
}

export const getSubscriptionTeamLimit = (stripeProductId?: string | null) => {
  return stripeProductId ? subscriptionMap[stripeProductId]?.team_limit : 30
}
