import dayjs from 'dayjs'
import JSZip from 'jszip'
import {
  Download,
  Expand,
  Filter,
  HelpingHand,
  Info,
  Mail,
  MailSearch,
  Minimize2,
  ReceiptText,
  SendHorizontal,
  Wand,
  X,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { useUsersApi } from 'src/api/db/apiUsers'
import { useReceiptsApi } from 'src/api/db/useReceiptsApi'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  Button,
  Input,
  ScrollArea,
  Tooltip,
} from 'src/shared/components/ui'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import { cn } from 'src/shared/components/ui/utils'

import { AccountsDialog } from './components/AccountsDialog'
import { DatePickerInbox } from './components/DatePickerInbox'
import { Receipt } from './components/Receipt'
import { SendReceiptsDialog } from './components/SendReceiptsDialog'

export const ReceiptsPage = () => {
  const { receiptsByOrg } = useReceiptsApi({})
  const { user } = useUsersApi()

  const [filterMonth, setFilterMonth] = useState<string>(
    dayjs().format('MMMM').toLowerCase()
  )
  const [filterEmail, setFilterEmail] = useState<string>('all')
  const [filterType, setFilterType] = useState<string>('all')
  const [filterStatus, setFilterStatus] = useState<string>('all')
  const [searchTerm, setSearchTerm] = useState('')

  const [dialogOpen, setDialogOpen] = useState(false)
  const [emailDialogOpen, setEmailDialogOpen] = useState(false)

  const [isFullscreen, setIsFullscreen] = useState(false)

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false)
  const [filterYear, setFilterYear] = useState<string>(dayjs().format('YYYY'))

  const [emails_date_from, setEmailsDateFrom] = useState<Date | undefined>()
  const [emails_date_to, setEmailsDateTo] = useState<Date | undefined>()

  const [invoice_date_from, setInvoiceDateFrom] = useState<Date | undefined>()
  const [invoice_date_to, setInvoiceDateTo] = useState<Date | undefined>()

  const [invoice_due_from, setInvoiceDueFrom] = useState<Date | undefined>()
  const [invoice_due_to, setInvoiceDueTo] = useState<Date | undefined>()

  const filteredReceipts = receiptsByOrg?.data
    ?.filter((receipt) => {
      const matchesSearch =
        searchTerm === '' ||
        receipt.sender?.name?.toLowerCase().includes(searchTerm.toLowerCase())

      const matchesMonth =
        filterMonth === 'all' ||
        dayjs(receipt.email_received).format('MMMM').toLowerCase() ===
          filterMonth.toLowerCase()

      const matchesEmail =
        filterEmail === 'all' || receipt.email_recipient === filterEmail

      const matchesType = filterType === 'all' //|| receipt.sender?.type === filterType

      const matchesEmailDates =
        (!emails_date_from ||
          dayjs(receipt.email_received).isAfter(emails_date_from)) &&
        (!emails_date_to ||
          dayjs(receipt.email_received).isBefore(emails_date_to))

      const matchesInvoiceDates =
        (!invoice_date_from ||
          dayjs(receipt.date_of_invoice).isAfter(invoice_date_from)) &&
        (!invoice_date_to ||
          dayjs(receipt.date_of_invoice).isBefore(invoice_date_to))

      const matchesInvoiceDueDates =
        (!invoice_due_from ||
          dayjs(receipt.due_date).isAfter(invoice_due_from)) &&
        (!invoice_due_to || dayjs(receipt.due_date).isBefore(invoice_due_to))

      const matchesYear =
        filterYear === 'all' ||
        dayjs(receipt.email_received).format('YYYY') === filterYear

      const matchesStatus =
        filterStatus === 'all' ||
        (filterStatus === 'warning' && Boolean(receipt.warning_info)) ||
        (filterStatus === 'no_warning' && !Boolean(receipt.warning_info)) ||
        (filterStatus === 'due_soon' &&
          dayjs(receipt.due_date).isAfter(new Date()))

      return (
        matchesSearch &&
        matchesMonth &&
        matchesEmail &&
        matchesType &&
        matchesEmailDates &&
        matchesInvoiceDates &&
        matchesInvoiceDueDates &&
        matchesYear &&
        matchesStatus
      )
    })
    ?.sort((a, b) => b.email_received!.localeCompare(a.email_received!))

  const uniqueEmails = Array.from(
    new Set(
      receiptsByOrg?.data?.map((r) => r.email_recipient).filter(Boolean)
    ) || []
  )

  const sendEmails = () => {
    setEmailDialogOpen(true)
  }

  const downloadUrls = () => {
    const urls = filteredReceipts
      ?.map((receipt) => receipt.receipt_file)
      .filter(Boolean)

    if (!urls?.length) return

    const zip = new JSZip()

    urls.forEach((url, i) => {
      fetch(url!)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = `${filterMonth}_receipts${decodeURIComponent(
            url!.split('public/receipts')[1]!
          )}`

          zip.file(filename, blob)

          if (i === urls.length - 1) {
            zip.generateAsync({ type: 'blob' }).then((zipBlob) => {
              const link = document.createElement('a')
              link.href = URL.createObjectURL(zipBlob)
              link.download = `${filterMonth}_receipts.zip`
              link.click()
            })
          }
        })
    })
  }

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsFullscreen(false)
      }
    }
    window.addEventListener('keydown', handleEscape)
    return () => window.removeEventListener('keydown', handleEscape)
  }, [])

  const toggleFullscreen = () => {
    const element = document.getElementById('inbox-page')
    if (!element) return

    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      }
    } else {
      try {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        }
      } catch (err) {}
    }
    setIsFullscreen(!isFullscreen)
  }

  return (
    <div
      id="inbox-page"
      className={cn(
        'h-screen',
        isFullscreen && 'bg-gradient-to-r from-[#181b20] to-[#16191d]'
      )}
    >
      <div className={cn(isFullscreen && 'p-6')}>
        <Column className="gap-4 w-full h-full">
          <Row className="gap-2 items-center mb-1 py-1">
            <ReceiptText className="w-5 h-5" />
            <h1 className="text-2xl">Receipts</h1>

            <Button
              variant="ghost"
              className="mr-auto h-8 w-8 text-white/80"
              size="icon"
              onClick={toggleFullscreen}
            >
              {isFullscreen ? (
                <Minimize2 className="w-4 h-4" />
              ) : (
                <Expand className="w-4 h-4" />
              )}
            </Button>
            <Row className="items-center ">
              <Tooltip
                content="Get a text message when an invoice is due"
                delayDuration={300}
              >
                <Button variant="ghost" className="ml-auto gap-2">
                  {user?.data?.phone ? (
                    <div className="w-2 h-2 rounded-full bg-green-500" />
                  ) : (
                    <div className="w-2 h-2 rounded-full bg-gray-400" />
                  )}
                  <p>SMS</p>
                </Button>
              </Tooltip>

              <Tooltip
                content="Add invoices to your calendar as reminders"
                delayDuration={300}
              >
                <Button variant="ghost" className="ml-auto gap-2 mr-3">
                  <div className="w-2 h-2 rounded-full bg-green-500" />
                  <p>Calendar</p>
                </Button>
              </Tooltip>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button className="ml-auto">
                    <Wand className="w-4 h-4 mr-2" />
                    Actions
                  </Button>
                </DropdownMenuTrigger>

                <DropdownMenuContent>
                  <DropdownMenuItem onClick={() => setDialogOpen(true)}>
                    <MailSearch className="w-4 h-4 mr-2" />
                    Scan emails
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    onClick={downloadUrls}
                    disabled={filteredReceipts?.length === 0}
                  >
                    <Download className="w-4 h-4 mr-2" />
                    Download ({filteredReceipts?.length})
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    onClick={sendEmails}
                    disabled={filteredReceipts?.length === 0}
                  >
                    <SendHorizontal className="w-4 h-4 mr-2" />
                    Send ({filteredReceipts?.length})
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </Row>
          </Row>

          <Row className="gap-2 items-center">
            <Input
              className="w-full"
              placeholder="Filter by sender"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <Select onValueChange={setFilterMonth} defaultValue={filterMonth}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="All months" />
              </SelectTrigger>

              <SelectContent>
                <SelectItem value="all">All months</SelectItem>
                <SelectItem value="january">
                  January (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) =>
                        dayjs(r.email_received).format('MMMM') === 'January'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="february">
                  February (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) =>
                        dayjs(r.email_received).format('MMMM') === 'February'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="march">
                  March (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) => dayjs(r.email_received).format('MMMM') === 'March'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="april">
                  April (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) => dayjs(r.email_received).format('MMMM') === 'April'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="may">
                  May (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) => dayjs(r.email_received).format('MMMM') === 'May'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="june">
                  June (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) => dayjs(r.email_received).format('MMMM') === 'June'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="july">
                  July (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) => dayjs(r.email_received).format('MMMM') === 'July'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="august">
                  August (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) => dayjs(r.email_received).format('MMMM') === 'August'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="september">
                  September (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) =>
                        dayjs(r.email_received).format('MMMM') === 'September'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="october">
                  October (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) =>
                        dayjs(r.email_received).format('MMMM') === 'October'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="november">
                  November (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) =>
                        dayjs(r.email_received).format('MMMM') === 'November'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="december">
                  December (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) =>
                        dayjs(r.email_received).format('MMMM') === 'December'
                    ).length
                  }
                  )
                </SelectItem>
              </SelectContent>
            </Select>

            <Select onValueChange={setFilterYear} defaultValue={filterYear}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="All years" />
              </SelectTrigger>

              <SelectContent>
                <SelectItem value="all">All years</SelectItem>
                <SelectItem value="2025">
                  2025 (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) => dayjs(r.email_received).format('YYYY') === '2025'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="2024">
                  2024 (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) => dayjs(r.email_received).format('YYYY') === '2024'
                    ).length
                  }
                  )
                </SelectItem>
                <SelectItem value="2023">
                  2023 (
                  {
                    receiptsByOrg?.data?.filter(
                      (r) => dayjs(r.email_received).format('YYYY') === '2023'
                    ).length
                  }
                  )
                </SelectItem>
              </SelectContent>
            </Select>

            <Button
              variant="ghost"
              className="w-fit"
              onClick={() => setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen)}
            >
              <Row className="items-center">
                <Filter className="w-3 h-3 mr-2" />
                <p className="text-sm">Advanced filters</p>
              </Row>
            </Button>
          </Row>

          <Accordion
            type="single"
            collapsible
            value={isAdvancedFiltersOpen ? 'filters' : ''}
            onValueChange={(value) =>
              setIsAdvancedFiltersOpen(value === 'filters')
            }
          >
            <AccordionItem value="filters">
              <AccordionContent>
                <Column className="gap-2">
                  <Row className="gap-2">
                    <Select onValueChange={setFilterEmail} defaultValue="all">
                      <SelectTrigger className="w-full ">
                        <SelectValue placeholder="All accounts" />
                      </SelectTrigger>

                      <SelectContent>
                        <SelectItem value="all">
                          <Row className="gap-2 items-center">
                            <Mail className="w-4 h-4" />
                            All accounts
                          </Row>
                        </SelectItem>
                        {uniqueEmails.map((email) => (
                          <SelectItem key={email} value={email || ''}>
                            {email}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <Select onValueChange={setFilterType} defaultValue="all">
                      <SelectTrigger className="w-full ">
                        <SelectValue placeholder="All types" />
                      </SelectTrigger>

                      <SelectContent>
                        <SelectItem value="all">
                          <Row className="gap-2 items-center">
                            <HelpingHand className="w-4 h-4" />
                            All vendor types
                          </Row>
                        </SelectItem>
                        <SelectItem value="software">Software</SelectItem>
                        <SelectItem value="service">Service</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectContent>
                    </Select>

                    <Select onValueChange={setFilterStatus} defaultValue="all">
                      <SelectTrigger className="w-full ">
                        <SelectValue placeholder="Status" />
                      </SelectTrigger>

                      <SelectContent>
                        <SelectItem value="all">
                          <Row className="gap-2 items-center">
                            <Info className="w-4 h-4" />
                            All statuses
                          </Row>
                        </SelectItem>
                        <SelectItem value="due_soon">Due soon</SelectItem>
                        <SelectItem value="warning">Warning info</SelectItem>
                        <SelectItem value="no_warning">No warning</SelectItem>
                      </SelectContent>
                    </Select>
                  </Row>

                  <Row className="gap-2">
                    <Row className="items-center p-1 w-fit pl-3">
                      <DatePickerInbox
                        date={emails_date_from}
                        setDate={setEmailsDateFrom}
                        placeholder="from"
                        label="Emails received"
                      />

                      <DatePickerInbox
                        date={emails_date_to}
                        setDate={setEmailsDateTo}
                        placeholder="to"
                        label="and"
                      />

                      {(emails_date_from || emails_date_to) && (
                        <Button
                          variant="ghost"
                          className="w-fit p-2"
                          onClick={() => {
                            setEmailsDateFrom(undefined)
                            setEmailsDateTo(undefined)
                          }}
                        >
                          <X className="w-4 h-4" />
                        </Button>
                      )}
                    </Row>

                    <Row className="items-center p-1 w-fit pl-3">
                      <DatePickerInbox
                        date={invoice_due_from}
                        setDate={setInvoiceDueFrom}
                        placeholder="from"
                        label="Invoice due dates"
                      />

                      <DatePickerInbox
                        date={invoice_due_to}
                        setDate={setInvoiceDueTo}
                        placeholder="to"
                        label="and"
                      />

                      {(invoice_due_from || invoice_due_to) && (
                        <Button
                          variant="ghost"
                          className="w-fit p-2"
                          onClick={() => {
                            setInvoiceDueFrom(undefined)
                            setInvoiceDueTo(undefined)
                          }}
                        >
                          <X className="w-4 h-4" />
                        </Button>
                      )}
                    </Row>

                    <Row className="items-center p-1 w-fit pl-3">
                      <DatePickerInbox
                        date={invoice_date_from}
                        setDate={setInvoiceDateFrom}
                        placeholder="from"
                        label="Date of invoice"
                      />

                      <DatePickerInbox
                        date={invoice_date_to}
                        setDate={setInvoiceDateTo}
                        placeholder="to"
                        label="and"
                      />

                      {(invoice_date_from || invoice_date_to) && (
                        <Button
                          variant="ghost"
                          className="w-fit p-2"
                          onClick={() => {
                            setInvoiceDateFrom(undefined)
                            setInvoiceDateTo(undefined)
                          }}
                        >
                          <X className="w-4 h-4" />
                        </Button>
                      )}
                    </Row>
                  </Row>
                </Column>
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <ScrollArea
            className="flex-grow pr-2 max-h-[calc(100vh-200px)] overflow-y-auto"
            scrollbarHide
          >
            <Column className="gap-2">
              {filteredReceipts?.map((receipt) => (
                <Receipt key={receipt.id} receipt={receipt as any} />
              ))}

              {filteredReceipts?.length === 0 && (
                <p className="text-center text-gray-500 text-sm">
                  No receipts found for current filters
                </p>
              )}
            </Column>
          </ScrollArea>
        </Column>

        <AccountsDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />

        <SendReceiptsDialog
          filteredReceipts={filteredReceipts || []}
          emailDialogOpen={emailDialogOpen}
          setEmailDialogOpen={setEmailDialogOpen}
        />
      </div>
    </div>
  )
}
