import { Receipt_ } from 'src/api/db/useReceiptsApi'

import { divisorMap } from '../consts'

// ------- Base calculations -------
export const calculateMonthlyPrice = (receipts?: Receipt_[]) => {
  if (!receipts) return 0

  let totalMonthlyPrice = 0
  receipts.forEach((receipt) => {
    let monthlyPrice = 0
    const divisor = divisorMap[receipt.renewal_frequency!] // Divide by 12, 4, or 1

    if (receipt?.pricing_model === 'PER_SEAT') {
      const pricePerSeat = receipt?.price_per_seat!
      const numberOfSeats = receipt?.number_of_seats!
      const totalPrice = pricePerSeat * numberOfSeats

      monthlyPrice = totalPrice / divisor
    }

    if (receipt?.pricing_model === 'FLAT_FEE')
      monthlyPrice = receipt?.flat_fee_cost! / divisor

    if (receipt?.pricing_model === 'USAGE_BASED')
      monthlyPrice = receipt?.usage_based_cost! / divisor

    if (receipt?.pricing_model === 'OTHER')
      monthlyPrice = receipt?.other_cost! / divisor

    totalMonthlyPrice += monthlyPrice
  })

  const countUniqueMonths = (dois: Receipt_[]) => {
    const uniqueMonths = new Set()
    dois.forEach((receipt) => {
      const date = new Date(receipt.date_of_invoice!)
      const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`
      uniqueMonths.add(yearMonth)
    })

    return uniqueMonths.size
  }
  const uniqueMonths = countUniqueMonths(receipts)
  return Math.round(totalMonthlyPrice / uniqueMonths)
}

export const calculateQuarterlyPrice = (receipts?: Receipt_[]) =>
  Math.round(calculateMonthlyPrice(receipts) * 3)

export const calculateAnnualPrice = (receipts?: Receipt_[]) =>
  Math.round(calculateMonthlyPrice(receipts) * 12)

// ------- Formatted calculations -------
export const calculateMonthlyPriceFormatted = (receipts?: Receipt_[]) => {
  if (!receipts) return 'N/A'

  const monthlyPrice = calculateMonthlyPrice(receipts)
  let formattedPrice = 'N/A'

  if (receipts[0]?.pricing_model === 'FLAT_FEE') {
    if (receipts[0]?.currency === 'USD') {
      formattedPrice = `$${monthlyPrice} / monthly`
    } else if (receipts[0]?.currency === 'SEK') {
      formattedPrice = `${monthlyPrice} kr / monthly`
    } else if (receipts[0]?.currency === 'EUR') {
      formattedPrice = `€${monthlyPrice} / monthly`
    } else {
      formattedPrice = `${receipts[0]?.currency} ${monthlyPrice} / monthly`
    }
  }

  if (receipts[0]?.pricing_model === 'PER_SEAT') {
    const pricePerSeat = receipts[0]?.price_per_seat!
    const numberOfSeats = receipts[0]?.number_of_seats!
    const totalPrice = pricePerSeat * numberOfSeats

    if (receipts[0]?.currency === 'USD') {
      formattedPrice = `($${pricePerSeat} x ${numberOfSeats} seats) $${totalPrice} / monthly`
    } else if (receipts[0]?.currency === 'SEK') {
      formattedPrice = `($${pricePerSeat} x ${numberOfSeats} seats) ${totalPrice} kr / monthly`
    } else if (receipts[0]?.currency === 'EUR') {
      formattedPrice = `($${pricePerSeat} x ${numberOfSeats} seats) €${totalPrice} / monthly`
    }
  }

  if (receipts[0]?.pricing_model === 'USAGE_BASED') {
    if (receipts[0]?.currency === 'USD') {
      formattedPrice = `$${monthlyPrice} / monthly`
    } else if (receipts[0]?.currency === 'SEK') {
      formattedPrice = `${monthlyPrice} kr / monthly`
    } else if (receipts[0]?.currency === 'EUR') {
      formattedPrice = `€${monthlyPrice} / monthly`
    }
  }

  if (receipts[0]?.pricing_model === 'OTHER') {
    if (receipts[0]?.currency === 'USD') {
      formattedPrice = `$${monthlyPrice}`
    } else if (receipts[0]?.currency === 'SEK') {
      formattedPrice = `${monthlyPrice} kr`
    } else if (receipts[0]?.currency === 'EUR') {
      formattedPrice = `€${monthlyPrice}`
    }
  }

  return formattedPrice
}

export const calculateQuarterlyPriceFormatted = (receipts?: Receipt_[]) => {
  if (!receipts) return 'N/A'

  const quarterlyPrice = calculateQuarterlyPrice(receipts)
  let formattedPrice = 'N/A'

  if (receipts[0]?.pricing_model === 'FLAT_FEE') {
    if (receipts[0]?.currency === 'USD') {
      formattedPrice = `$${quarterlyPrice} / quarterly`
    } else if (receipts[0]?.currency === 'SEK') {
      formattedPrice = `${quarterlyPrice} kr / quarterly`
    } else if (receipts[0]?.currency === 'EUR') {
      formattedPrice = `€${quarterlyPrice} / quarterly`
    }
  }

  if (receipts[0]?.pricing_model === 'PER_SEAT') {
    const pricePerSeat = receipts[0]?.price_per_seat!
    const numberOfSeats = receipts[0]?.number_of_seats!
    const totalPrice = pricePerSeat * numberOfSeats

    if (receipts[0]?.currency === 'USD') {
      formattedPrice = `($${pricePerSeat} x ${numberOfSeats} seats) $${totalPrice} / quarterly`
    }
  }

  if (receipts[0]?.pricing_model === 'USAGE_BASED') {
    if (receipts[0]?.currency === 'USD') {
      formattedPrice = `$${quarterlyPrice} / quarterly`
    } else if (receipts[0]?.currency === 'SEK') {
      formattedPrice = `${quarterlyPrice} kr / quarterly`
    } else if (receipts[0]?.currency === 'EUR') {
      formattedPrice = `€${quarterlyPrice} / quarterly`
    }
  }

  if (receipts[0]?.pricing_model === 'OTHER') {
    if (receipts[0]?.currency === 'USD') {
      formattedPrice = `$${quarterlyPrice} / quarterly`
    } else if (receipts[0]?.currency === 'SEK') {
      formattedPrice = `${quarterlyPrice} kr / quarterly`
    } else if (receipts[0]?.currency === 'EUR') {
      formattedPrice = `€${quarterlyPrice} / quarterly`
    }
  }

  return formattedPrice
}

export const calculateAnnualPriceFormatted = (receipts?: Receipt_[]) => {
  if (!receipts) return 'N/A'
  const annualPrice = calculateAnnualPrice(receipts)

  if (receipts[0]?.currency === 'USD') {
    return `$${annualPrice} / annually`
  } else if (receipts[0]?.currency === 'SEK') {
    return `${annualPrice} kr / annually`
  } else if (receipts[0]?.currency === 'EUR') {
    return `€${annualPrice} / annually`
  }

  return `${receipts[0]?.currency} ${annualPrice} / annually`
}
