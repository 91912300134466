import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { ArrowRight, Check, Loader, LoaderPinwheel, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNotifications } from 'src/api/db/apiNotifications'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { queryKeys } from 'src/api/db/queryKeys'
import { useSupabaseClient } from 'src/api/supabase'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
} from 'src/shared/components/ui'
import {
  Card,
  Dialog,
  DialogContent,
  ScrollArea,
} from 'src/shared/components/ui'

import { useToast } from '../hooks/use-toast'
import { cn } from './ui/utils'

export const CommandCentral = () => {
  const [open, setOpen] = useState(false)
  const { notifications } = useNotifications()
  const [loading, setLoading] = useState(false)

  const { toast } = useToast()
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()

  const { orgId } = useOrgIdApi()

  const activities =
    notifications?.data?.filter(({ tag }) => tag?.includes('activity')) || []
  const emails =
    notifications?.data?.filter(({ tag }) => tag?.includes('email')) || []

  useEffect(() => {
    let loading1 = false
    let loading2 = false

    if (activities.length) {
      loading1 = !activities?.[0]?.tag?.includes('finished')
    }
    if (emails.length) {
      loading2 = !emails?.[0]?.tag?.includes('finished')
    }
    queryClient.invalidateQueries({ queryKey: [queryKeys.receipts] })

    setLoading(Boolean(loading1 || loading2))
  }, [notifications?.data])

  useEffect(() => {
    const changes = supabase
      .channel('notifications-realtime')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'notification',
          filter: `organization_id=eq.${orgId}`,
        },
        (payload: any) => {
          queryClient.invalidateQueries({ queryKey: [queryKeys.notifications] })

          const { created_at, dataObject, title } = payload.new
          toast({
            itemID: created_at,
            delay: 6000,
            variant: 'success',
            // @ts-ignore
            title: <p className="text-xs  text-muted-foreground">{title}</p>,
            description: (
              <Column className="gap-2">
                <Row className="justify-between items-center gap-2">
                  <p className="text-muted-foreground text-xs">
                    {dayjs(created_at).fromNow()}
                  </p>
                </Row>

                {dataObject && (
                  <Row className="gap-2 ">
                    <p className="text-xs text-muted-foreground">
                      {dataObject}
                    </p>
                  </Row>
                )}
              </Column>
            ),
          })
        }
      )
      .subscribe()

    return () => {
      changes.unsubscribe()
    }
  }, [orgId])

  const TodoRow = ({
    title,
    onClickYes,
    onClickNo,
    goTo,
  }: {
    title: string
    onClickYes?: () => void
    onClickNo?: () => void
    goTo?: () => void
  }) => (
    <Row className="gap-2 items-center border border-white/85 p-2 rounded-full w-[210px]">
      <p className="ml-1 text-xs mr-auto">{title}</p>

      {onClickNo && (
        <Button
          variant="secondary"
          size="icon"
          className="h-6 w-6 text-white/85"
        >
          <X className="w-4 h-4" />
        </Button>
      )}

      {onClickYes && (
        <Button size="icon" className="h-6 w-6 text-green-500">
          <Check className="w-4 h-4" />
        </Button>
      )}

      {goTo && (
        <Button size="icon" className="h-6 w-6 text-green-500" onClick={goTo}>
          <ArrowRight className="w-4 h-4" />
        </Button>
      )}
    </Row>
  )

  const TodoCard = () => (
    <Card className="rounded-[20px] p-4 h-full">
      <Column className="mb-3">
        <p className="text-sm  mb-2">Todos</p>

        <Column className="gap-2">
          <TodoRow
            title="Download extension"
            onClickNo={() => {}}
            onClickYes={() => {}}
          />

          <TodoRow
            title="Invite extension users"
            onClickNo={() => {}}
            goTo={() => {}}
          />
        </Column>
      </Column>
    </Card>
  )

  const DataCard = ({
    data,
    cardTitle,
    description,
  }: {
    data: any[]
    cardTitle: string
    description: string
  }) => (
    <Card className="rounded-[20px] p-4 h-full">
      <Column className="mb-3">
        <Row className="items-center">
          <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse mr-2" />
          <p className="text-sm ">{cardTitle}</p>
        </Row>

        <p className="text-xs text-muted-foreground">{description}</p>
      </Column>

      {data
        ?.slice(0, 1)
        .map(({ id, created_at, tag, dataObject, dataArray, title }) => (
          <Column key={id} className="gap-2">
            <Row className="items-center gap-2" key={id}>
              {tag?.includes('finished') ? (
                <Check className="w-3 h-3 text-green-500" />
              ) : (
                <Loader className="w-3 h-3 animate-spin [animation-duration:2s]" />
              )}

              <p className="text-xs font-sm muted-foreground text-left mr-auto">
                {title}
              </p>

              <p className="text-muted-foreground">
                {dayjs(created_at).fromNow()}
              </p>
            </Row>

            {dataObject && (
              <Row className="p-2 rounded-lg gap-2 border">
                <p className="text-xs text-muted-foreground">{dataObject}</p>
              </Row>
            )}
          </Column>
        ))}

      <ScrollArea className="h-[170px] mt-4">
        <Column className="gap-2 mr-4">
          {data
            ?.slice(1)
            .map(({ id, created_at, tag, dataObject, dataArray, title }) => (
              <Column key={id} className=" p-2 rounded-lg">
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-1">
                    <AccordionTrigger
                      className="text-xs text-muted-foreground w-full"
                      disabled={!dataObject}
                      hideChevron={!dataObject}
                    >
                      <Row className="justify-between w-full" key={id}>
                        <p className="text-left">
                          {title.length > 30
                            ? title.slice(0, 30) + '...'
                            : title}
                        </p>
                        <p>{dayjs(created_at).fromNow()}</p>
                      </Row>
                    </AccordionTrigger>

                    <AccordionContent>
                      <Column className=" p-1 rounded-lg w-fit">
                        <p className="text-xs text-muted-foreground">
                          {JSON.stringify(dataObject)}
                        </p>
                      </Column>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </Column>
            ))}
        </Column>
      </ScrollArea>
    </Card>
  )

  return (
    <div>
      <Column className="absolute bottom-4 right-4 gap-2">
        <Button
          onClick={() => setOpen(true)}
          variant="transparent"
          className={cn('w-fit ml-auto')}
        >
          <LoaderPinwheel
            className={cn(
              'w-4 h-4 mr-2',
              loading && 'animate-[spin_7s_linear_infinite] text-orange-500'
            )}
          />
          {loading ? (
            <p className="bg-gradient-to-r from-orange-500 to-orange-300 bg-clip-text text-transparent animate-pulse">
              Working...
            </p>
          ) : (
            <p>Smart Panel</p>
          )}
        </Button>
      </Column>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent showClose={false} className="rounded-[30px] p-3">
          <div className="grid grid-cols-6 gap-3 text-xs">
            <div className="col-span-3">
              <TodoCard />
            </div>

            <div className="col-span-2">{/* <DataCard2 /> */}</div>

            <div className="col-span-3">
              <DataCard
                data={activities}
                cardTitle="User activity"
                description="Extension will scan every 10 minutes"
              />
            </div>

            <div className="col-span-3">
              <DataCard
                data={emails}
                cardTitle="Gmail sync"
                description="Gmail will be scanned manually, or per your schedule."
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
