import { useGoogleLogin } from '@react-oauth/google'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import {
  ArrowLeft,
  ArrowRight,
  Check,
  Chrome,
  Coins,
  CreditCard,
  HelpingHand,
  Loader,
  MailSearch,
  PartyPopper,
  X,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { useEmailAccountsApi } from 'src/api/db/apiEmailAccounts'
import { useNotifications } from 'src/api/db/apiNotifications'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useToolsApi } from 'src/api/db/apiTools'
import { useUsersApi } from 'src/api/db/apiUsers'
import { queryKeys } from 'src/api/db/queryKeys'
import { useReceiptsApi } from 'src/api/db/useReceiptsApi'
import { useScanEmailAccount } from 'src/api/server/scanEmailAccount'
import { ServerRoutes } from 'src/api/server/serverRoutes'
import { useSupabaseClient } from 'src/api/supabase'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Card,
  Image,
  ScrollArea,
} from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import { Button } from 'src/shared/components/ui/button'
import { Dialog, DialogContent } from 'src/shared/components/ui/dialog'
import { useAuth } from 'src/shared/hooks/authProvider'

import { Progress } from '../ui/progress'

const DataCard = ({
  data,
  cardTitle,
  description,
}: {
  data: any[]
  cardTitle: string
  description: string
}) => (
  <Column>
    <Column className="mb-3">
      <Row className="items-center">
        <MailSearch className="w-4 h-4 mr-2 text-orange-500" />
        <p className="text-sm">{cardTitle}</p>
      </Row>

      <p className="text-xs text-muted-foreground mt-1">{description}</p>
    </Column>

    {data
      ?.slice(0, 1)
      .map(({ id, created_at, tag, dataObject, dataArray, title }) => (
        <Column key={id} className="gap-2 p-2">
          <Row className="justify-between items-center" key={id}>
            <Loader className="w-4 h-4 animate-spin mr-2" />
            <p className="text-sm  text-muted-foreground flex-1">{title}</p>
            <p className="text-xs text-muted-foreground">
              {dayjs(created_at).fromNow()}
            </p>
          </Row>

          {dataObject && (
            <Row className=" p-2 rounded-lg gap-2 border">
              <p className="text-xs text-muted-foreground">{dataObject}</p>
            </Row>
          )}
        </Column>
      ))}

    <ScrollArea className="h-[270px] mt-4">
      <Column className="gap-2 mr-4">
        {data?.slice(1).map(({ id, created_at, tag, dataObject, title }) => (
          <Column key={id} className=" p-2 rounded-lg">
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger
                  className="text-xs text-muted-foreground w-full"
                  disabled={!dataObject}
                  hideChevron={!dataObject}
                >
                  <Row className="justify-between w-full" key={id}>
                    <p>{title}</p>
                    <p>{dayjs(created_at).fromNow()}</p>
                  </Row>
                </AccordionTrigger>

                <AccordionContent>
                  <Column className=" p-1 rounded-lg w-fit">
                    <p className="text-xs text-muted-foreground">
                      {JSON.stringify(dataObject)}
                    </p>
                  </Column>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </Column>
        ))}
      </Column>
    </ScrollArea>
  </Column>
)

const ChooseProducts = ({
  page,
  setPage,
  receipts,
  setReceipts,
  software,
  setSoftware,
}: {
  page: number
  setPage: (page: number) => void
  receipts: boolean
  setReceipts: (receipts: boolean) => void
  software: boolean
  setSoftware: (software: boolean) => void
}) => {
  const ProductCard = ({
    title,
    description,
    checked,
    setChecked,
  }: {
    title: JSX.Element
    description: string
    checked: boolean
    setChecked: (checked: boolean) => void
  }) => (
    <div
      className={`relative cursor-pointer`}
      onClick={() => setChecked(!checked)}
    >
      <Column
        className={`p-6 w-full h-[200px] rounded-2xl bg-gradient-to-br from-[#16191d] to-[#16191d] justify-end ${
          checked ? 'glass-button-transparent' : 'glass-button-transparent'
        }`}
      >
        {/* <Badge className="w-fit rounded-full">Struggling with receipts</Badge> */}
        {title}
        <p className="text-xs font-light mt-2">{description}</p>
      </Column>

      <div className="absolute top-3 right-3  rounded-full w-6 h-6 flex items-center justify-center bg-gradient-to-br from-[#16191d] to-[#16191d] border border-white/10">
        {checked ? (
          <Check className="w-[14px] h-[14px] text-green-500" />
        ) : (
          <X className="w-[14px] h-[14px] text-red-500" />
        )}
      </div>
    </div>
  )

  return (
    <Column className="p-6 w-full h-full bg-[url('https://i.ibb.co/yFQPgwH/Ska-rmavbild-2025-01-19-kl-13-32-56.png')] bg-cover bg-center rounded-lg">
      <p className="text-2xl font-extralight ml-1 bg-gradient-to-tr from-white/80 to-white/60 bg-clip-text text-transparent">
        Hello, and welcome to PinnOne!
      </p>
      <p className="text-lg font-extralight ml-1 bg-gradient-to-tr from-white/80 to-white/60 bg-clip-text text-transparent">
        The financial assistant for your Google Suite
      </p>

      <p className="text-sm ml-1 mt-auto font-light">
        Which products do you want to onboard?
      </p>
      <div className="grid grid-cols-2 gap-2 w-full mt-4">
        <ProductCard
          title={
            <p className="text-lg font-light mt-1">
              PinnOne
              <span className="ml-1 bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">
                Receipts
              </span>
            </p>
          }
          description="Automatically fetch, organize, and send your receipts, relieving you from the ongoing stress of accounting."
          checked={receipts}
          setChecked={setReceipts}
        />

        <ProductCard
          title={
            <p className="text-lg font-light mt-1">
              PinnOne
              <span className="ml-1 bg-gradient-to-r from-orange-300 to-orange-500 bg-clip-text text-transparent">
                Software Manager
              </span>
            </p>
          }
          description="Get an overview over your tool stack, find and eliminate wasteful spend. 100% automatic."
          checked={software}
          setChecked={setSoftware}
        />
      </div>

      <Button
        className="ml-auto mt-6"
        onClick={() => setPage(page + 1)}
        disabled={!receipts && !software}
      >
        Activate product{receipts && software ? 's' : ''}
        <ArrowRight className="w-4 h-4 ml-2" />
      </Button>
    </Column>
  )
}

const AddAccount = ({
  setPage,
  page,
}: {
  setPage: (page: number) => void
  page: number
}) => {
  const { session } = useAuth()
  const { orgId } = useOrgIdApi()
  const { emailAccountFromEmail } = useEmailAccountsApi({
    email: session?.user?.email,
  })
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const scanEmailAccount = useScanEmailAccount()
  const { orgUser } = useOrgUsersApi()

  useEffect(() => {
    const changes = supabase
      .channel('email_accounts-realtime')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'email_account',
          filter: `organization_id=eq.${orgId}`,
        },
        () => {
          queryClient.invalidateQueries({
            queryKey: [queryKeys.emailAccount],
          })
        }
      )
      .subscribe()

    return () => {
      changes.unsubscribe()
    }
  }, [orgId])

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/tasks.readonly https://www.googleapis.com/auth/tasks',
    onSuccess: async (codeResponse) => {
      await fetch(
        process.env.REACT_APP_BE_SERVER_URL + ServerRoutes.googleAuth,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: codeResponse.code,
            organization_id: orgId,
          }),
        }
      )
    },
    onError: (errorResponse) => console.log(errorResponse),
  })

  useEffect(() => {
    if (emailAccountFromEmail?.data?.length) {
      scanEmailAccount.mutate({
        email: emailAccountFromEmail?.data[0]?.email!,
        organization_id: orgId,
        org_user_id: orgUser?.data?.id!,

        after: format(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          'yyyy/M/d'
        ),
        before: format(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
          'yyyy/M/d'
        ),
      })

      setPage(page + 1)
    }
  }, [emailAccountFromEmail?.data])

  return (
    <Column className="w-full h-full p-6">
      <Column className="m-auto gap-3">
        <p className="text-md font-extralight">
          Start by scanning{' '}
          <span className="bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent font-medium">
            {session?.user?.email}
          </span>{' '}
          for invoices and receipts!
        </p>

        <Button className="w-fit m-auto" onClick={googleLogin}>
          <MailSearch className="w-4 h-4 mr-2" />
          Scan inbox
        </Button>
      </Column>
    </Column>
  )
}

const ScanningGmail = ({
  setPage,
  page,
}: {
  setPage: (page: number) => void
  page: number
}) => {
  const { orgId } = useOrgIdApi()
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { notifications } = useNotifications()

  useEffect(() => {
    const changes = supabase
      .channel('notifications-onboarding-realtime')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'notification',
          filter: `organization_id=eq.${orgId}`,
        },
        () => {
          queryClient.invalidateQueries({
            queryKey: [queryKeys.notifications],
          })
        }
      )
      .subscribe()

    return () => {
      changes.unsubscribe()
    }
  }, [orgId])

  const emails =
    notifications?.data?.filter(({ tag }) => tag?.includes('email')) || []

  const count1 = emails?.[0]?.dataObject?.toString()?.split(' ')[2] || 0
  const count2 =
    emails?.[0]?.dataObject?.toString()?.split(' ')[4]?.split(':')[0] || 1
  const count3 = (Number(count1) / Number(count2)) * 100

  useEffect(() => {
    if (emails.find(({ tag }) => tag?.includes('finished'))) {
      setPage(page + 1)
    }
  }, [emails])

  return (
    <Column className="w-full h-full p-6">
      <DataCard
        data={emails}
        cardTitle="Syncing your Gmail"
        description="Scanning invoices and receipts for this month. This may take a few minutes."
      />
      <Progress
        value={count3}
        className="mt-4"
        indicatorColor="bg-orange-500"
      />
    </Column>
  )
}

const SeeReceipts = ({
  setPage,
  page,
  software,
  receipts,
}: {
  setPage: (page: number) => void
  page: number
  software: boolean
  receipts: boolean
}) => {
  const { receiptsByOrg } = useReceiptsApi({})
  const { session } = useAuth()
  const { updateUser } = useUsersApi()

  return (
    <ScrollArea className="w-full h-full mt-4 relative p-6">
      <Column className="w-full h-full">
        <Column className="mb-2 gap-1">
          <Row className="items-center gap-2">
            <p className="text-md font-extralight ">
              Super easy, right? Next step is to send these to your accountant.
            </p>
            <PartyPopper className="w-4 h-4 text-orange-500" />
          </Row>

          <Row className="items-center gap-1">
            <p className="text-sm font-extralight">Just go to</p>

            <Badge className="w-fit rounded-full gap-1">
              <span className="bg-gradient-to-r from-orange-400 to-orange-600 bg-clip-text text-transparent">
                Receipts
              </span>
              →
              <span className="bg-gradient-to-r from-orange-400 to-orange-600 bg-clip-text text-transparent">
                Actions
              </span>
              →
              <span className="bg-gradient-to-r from-orange-400 to-orange-600 bg-clip-text text-transparent">
                Send
              </span>
            </Badge>
          </Row>
        </Column>

        <div className="grid grid-cols-2 gap-2 w-full">
          {receiptsByOrg?.data?.length ? (
            receiptsByOrg?.data?.map((receipt) => (
              <Card className="p-4 rounded-lg relative">
                <Column className="p-2 pb-6 gap-[5px] absolute top-0 right-0 bg-gradient-to-b to-transparent from-black/75 w-full rounded-lg">
                  <Badge className="w-fit rounded-full">
                    <HelpingHand className="w-4 h-4 mr-2" />
                    {receipt.sender?.name}
                  </Badge>

                  <Badge className="w-fit rounded-full">
                    <Coins className="w-4 h-4 mr-2" />
                    {`${receipt.total_cost} ${receipt.currency}`}
                  </Badge>

                  {receipt.due_date && (
                    <Badge className="w-fit rounded-full">
                      <CreditCard className="w-4 h-4 mr-2" />
                      Due {dayjs(receipt.due_date).fromNow()}
                    </Badge>
                  )}

                  {receipt.type === 'software' && (
                    <Badge className="w-fit rounded-full">
                      <CreditCard className="w-4 h-4 mr-2" />
                      Software
                    </Badge>
                  )}
                </Column>

                <Image
                  src={receipt.receipt_file || ''}
                  alt="receipt"
                  width={200}
                  className="w-full h-[300px] object-cover rounded-lg"
                />
              </Card>
            ))
          ) : (
            <p className="text-sm font-extralight">
              No receipts found this month for {session?.user?.email}.
            </p>
          )}
        </div>
      </Column>

      {receipts && software && (
        <Button
          className="absolute bottom-2 left-1/2 transform -translate-x-1/2"
          onClick={() => setPage(page + 1)}
        >
          Next
          <ArrowRight className="w-4 h-4 ml-2" />
        </Button>
      )}

      {receipts && !software && (
        <Button
          className="absolute bottom-2 left-1/2 transform -translate-x-1/2"
          onClick={() =>
            updateUser.mutate({
              id: session?.user?.id!,
              onboarded: true,
              email: session?.user?.email!,
            })
          }
        >
          Finish
          <Check className="w-4 h-4 ml-2" />
        </Button>
      )}
    </ScrollArea>
  )
}

const Extension = ({
  setPage,
  page,
}: {
  setPage: (page: number) => void
  page: number
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { orgId } = useOrgIdApi()
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { notifications } = useNotifications()

  useEffect(() => {
    const changes = supabase
      .channel('user_activity-onboarding-realtime')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'user_activity',
          filter: `organization_id=eq.${orgId}`,
        },
        () => {
          queryClient.invalidateQueries({
            queryKey: [queryKeys.user_activities],
          })
        }
      )
      .subscribe()

    return () => {
      changes.unsubscribe()
    }
  }, [orgId])

  const activities =
    notifications?.data?.filter(({ tag }) => tag?.includes('activity')) || []

  useEffect(() => {
    if (activities.length) {
      setPage(page + 1)
    }
  }, [activities])

  return (
    <Column className="w-full h-full p-6">
      {isLoading ? (
        <Button
          className="m-auto"
          variant="link"
          onClick={() => {
            setIsLoading(true)
            window.open(
              'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
              '_blank'
            )
          }}
        >
          <Loader className="mr-2 w-4 h-4 animate-spin text-orange-500" />
          Just a second...
        </Button>
      ) : (
        <Button
          className="m-auto"
          variant="link"
          onClick={() => {
            setIsLoading(true)
            window.open(
              'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
              '_blank'
            )
          }}
        >
          <Chrome className="mr-2 w-4 h-4" />
          Download Extension
        </Button>
      )}
    </Column>
  )
}

const ScanningActivity = ({
  setPage,
  page,
}: {
  setPage: (page: number) => void
  page: number
}) => {
  const { orgId } = useOrgIdApi()
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { notifications } = useNotifications()

  useEffect(() => {
    const changes = supabase
      .channel('notifications-onboarding-realtime')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'notification',
          filter: `organization_id=eq.${orgId}`,
        },
        () => {
          queryClient.invalidateQueries({
            queryKey: [queryKeys.notifications],
          })
        }
      )
      .subscribe()

    return () => {
      changes.unsubscribe()
    }
  }, [orgId])

  const activities =
    notifications?.data?.filter(({ tag }) => tag?.includes('activity')) || []

  const count1 = activities?.[0]?.dataObject?.toString()?.split(' ')[2] || 0
  const count2 =
    activities?.[0]?.dataObject?.toString()?.split(' ')[4]?.split(':')[0] || 1
  const count3 = (Number(count1) / Number(count2)) * 100

  useEffect(() => {
    if (activities.find(({ tag }) => tag?.includes('finished'))) {
      setPage(page + 1)
    }
  }, [activities])

  return (
    <Column className="w-full h-full p-6">
      <DataCard
        data={activities}
        cardTitle="User activity"
        description="Chrome extension feed"
      />
      <Progress
        value={count3}
        className="mt-4"
        indicatorColor="bg-orange-500"
      />
    </Column>
  )
}

const SeeTools = ({
  setPage,
  page,
}: {
  setPage: (page: number) => void
  page: number
}) => {
  const { session } = useAuth()
  const { updateUser } = useUsersApi()
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({
    organization_id: orgId,
  })
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.tools],
    })
  }, [])

  return (
    <ScrollArea className="w-full h-full p-6">
      <Column className="w-full h-full">
        <Row className="items-center mb-2 gap-2">
          <p className="text-md font-extralight ">
            These are the tools that you have used in the past few weeks!
          </p>
        </Row>

        <p className="text-sm font-extralight mb-4">
          We will automatically track the{' '}
          <span className=" bg-gradient-to-r from-blue-300 to-blue-500 bg-clip-text text-transparent">
            usage
          </span>
          ,{' '}
          <span className=" bg-gradient-to-r from-orange-300 to-orange-500 bg-clip-text text-transparent">
            cost
          </span>{' '}
          and{' '}
          <span className=" bg-gradient-to-r from-red-400 to-red-600 bg-clip-text text-transparent">
            wasteful spend
          </span>{' '}
          for these tools and{' '}
          <span className=" bg-gradient-to-r from-green-400 to-green-600 bg-clip-text text-transparent">
            detect new tools
          </span>{' '}
          for you.
        </p>

        <div className="grid grid-cols-2 gap-2 w-full">
          {tools?.tools?.map((tool) => (
            <Card className="p-4 rounded-lg relative">
              <Column className="gap-2">
                <Row className="items-center gap-2">
                  <SaasIcon
                    src={tool.vendor?.logo_url || tool.sender?.name}
                    toolName={tool.name || ''}
                    size="sm"
                  />
                  <p className="text-sm ">{tool.name}</p>
                </Row>

                {tool.sender?.name && (
                  <Row className="gap-2 items-center">
                    <p className="text-xs text-muted-foreground">
                      Tracking cost from
                    </p>
                    <Badge className="rounded-full" variant="light">
                      {tool.sender?.name}
                    </Badge>
                  </Row>
                )}

                {tool.root_domain && (
                  <Row className="gap-2 items-center">
                    <p className="text-xs text-muted-foreground">
                      Tracking usage from
                    </p>
                    <Badge className="rounded-full" variant="light">
                      {tool.root_domain}
                    </Badge>
                  </Row>
                )}

                {tool.description && (
                  <p className="text-xs text-muted-foreground">
                    {tool.description}
                  </p>
                )}

                {tool.department && (
                  <p className="text-xs text-muted-foreground">
                    Category: {tool.department}
                  </p>
                )}

                {tool.calculated.priceMonthFormatted && (
                  <p className="text-xs text-muted-foreground">
                    {tool.calculated.priceMonthFormatted}
                  </p>
                )}
              </Column>
            </Card>
          ))}
        </div>
      </Column>

      <Button
        className="absolute bottom-2 left-1/2 transform -translate-x-1/2"
        onClick={() =>
          updateUser.mutate({
            id: session?.user?.id!,
            onboarded: true,
            email: session?.user?.email!,
          })
        }
      >
        Finish
        <Check className="w-4 h-4 ml-2" />
      </Button>
    </ScrollArea>
  )
}

export const OnboardingWidgetNew = () => {
  const [page, setPage] = useState(0)
  const [onboard_receipts, setOnboardReceipts] = useState(true)
  const [onboard_software, setOnboardSoftware] = useState(true)

  const pages = [
    <ChooseProducts
      page={page}
      setPage={setPage}
      receipts={onboard_receipts}
      setReceipts={setOnboardReceipts}
      software={onboard_software}
      setSoftware={setOnboardSoftware}
    />,
  ]

  if (onboard_receipts) {
    pages.push(
      <AddAccount page={page} setPage={setPage} />,
      <ScanningGmail page={page} setPage={setPage} />,
      <SeeReceipts
        page={page}
        setPage={setPage}
        software={onboard_software}
        receipts={onboard_receipts}
      />
    )
  }

  if (onboard_software) {
    pages.push(
      <Extension page={page} setPage={setPage} />,
      <ScanningActivity page={page} setPage={setPage} />,
      <SeeTools page={page} setPage={setPage} />
    )
  }

  return (
    <Dialog open={true} onOpenChange={() => {}}>
      <DialogContent showClose={false} className="w-[900px] h-[500px] p-0">
        <div className="hidden flex justify-between items-center absolute bottom-0 left-0 w-full p-2">
          <Button
            onClick={() => setPage(page - 1)}
            size={'icon'}
            disabled={page === 1}
          >
            <ArrowLeft className="w-4 h-4" />
          </Button>

          <Button
            onClick={() => setPage(page + 1)}
            size={'icon'}
            disabled={page === 6}
          >
            <ArrowRight className="w-4 h-4" />
          </Button>
        </div>

        {pages[page]}
      </DialogContent>
    </Dialog>
  )
}
