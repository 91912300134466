import { Receipt_ } from 'src/api/db/useReceiptsApi'

export const getInfo = (subscription: Receipt_) => {
  const renewal_frequency =
    subscription.renewal_frequency === 'MONTHLY'
      ? '/ month'
      : subscription.renewal_frequency === 'YEARLY'
      ? '/ year'
      : subscription.renewal_frequency === 'QUARTERLY'
      ? '/ quarter'
      : subscription.renewal_frequency === 'OTHER'
      ? ''
      : subscription.renewal_frequency

  let cost
  if (subscription.pricing_model === 'FLAT_FEE') {
    cost = `$${subscription.flat_fee_cost} ${renewal_frequency}`

    if (subscription.currency === 'SEK') {
      cost = `${subscription.flat_fee_cost} kr ${renewal_frequency}`
    } else if (subscription.currency === 'EUR') {
      cost = `€${subscription.flat_fee_cost} ${renewal_frequency}`
    }
  } else if (subscription.pricing_model === 'USAGE_BASED') {
    cost = `$${subscription.usage_based_cost} ${renewal_frequency}`

    if (subscription.currency === 'SEK') {
      cost = `${subscription.usage_based_cost} kr ${renewal_frequency}`
    } else if (subscription.currency === 'EUR') {
      cost = `€${subscription.usage_based_cost} ${renewal_frequency}`
    }
  } else if (subscription.pricing_model === 'PER_SEAT') {
    cost = `$${subscription.price_per_seat} x ${
      subscription.number_of_seats
    } seats = $${
      (subscription.price_per_seat || 0) * (subscription.number_of_seats || 0)
    } ${renewal_frequency}`

    if (subscription.currency === 'SEK') {
      cost = `${subscription.price_per_seat} kr x ${
        subscription.number_of_seats
      } seats = ${
        (subscription.price_per_seat || 0) * (subscription.number_of_seats || 0)
      } kr ${renewal_frequency}`
    }
  } else if (subscription.pricing_model === 'OTHER') {
    cost = `$${subscription.other_cost} ${renewal_frequency}`

    if (subscription.currency === 'SEK') {
      cost = `${subscription.other_cost} kr ${renewal_frequency}`
    } else if (subscription.currency === 'EUR') {
      cost = `€${subscription.other_cost} ${renewal_frequency}`
    }
  }

  return { cost }
}
