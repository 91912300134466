import {
  Activity,
  ArrowLeft,
  LucideCreditCard,
  Settings2,
  SquareKanban,
} from 'lucide-react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { paths } from 'src/routes/paths'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'

import { Costs } from './tabs/Costs'
import { Home } from './tabs/Home'
import { Settings } from './tabs/Settings'
import { Usage } from './tabs/Usage'

export const SubscriptionPage = () => {
  const { tool_id } = useParams()
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tool = tools?.tools?.find((tool) => {
    return tool.id === Number(tool_id)
  })
  const navigate = useNavigate()
  const location = useLocation()

  const currentTab = location.hash.slice(1) || 'overview'

  const handleTabChange = (value: string) => {
    navigate(`#${value}`)
  }

  return (
    <Column className="gap-4 w-full">
      <Column className="gap-4 w-full">
        <Row className="gap-2 items-center mb-1">
          <Button
            className="mr-1"
            onClick={() => navigate(paths.software_table)}
            variant="light"
          >
            <ArrowLeft className="h-4 w-4 mr-1" />
            Back
          </Button>

          <SaasIcon
            toolName={tool?.name || ''}
            src={tool?.org_vendor?.logo_url || ''}
          />

          <h1 className="text-2xl ">{tool?.name || ''}</h1>
        </Row>

        <Tabs value={currentTab} onValueChange={handleTabChange}>
          <TabsList className="w-fit">
            <TabsTrigger value="overview">
              <SquareKanban className="w-4 h-4 mr-2" />
              Overview
            </TabsTrigger>

            {tool?.sender_id && (
              <TabsTrigger value="costs">
                <LucideCreditCard className="w-4 h-4 mr-2" />
                Costs
              </TabsTrigger>
            )}

            {tool?.is_tracking && (
              <TabsTrigger value="usage">
                <Activity className="w-4 h-4 mr-2" />
                Usage
              </TabsTrigger>
            )}

            <TabsTrigger value="settings">
              <Settings2 className="w-4 h-4 mr-2" />
              Settings
            </TabsTrigger>
          </TabsList>

          <TabsContent value="overview">
            <Home />
          </TabsContent>

          <TabsContent value="costs">
            <Costs />
          </TabsContent>

          <TabsContent value="usage">
            <Usage />
          </TabsContent>

          <TabsContent value="settings">
            <Settings />
          </TabsContent>
        </Tabs>
      </Column>
    </Column>
  )
}
