import { useQueryClient } from '@tanstack/react-query'
import {
  AlertCircle,
  Blend,
  ChartColumnDecreasing,
  RefreshCcw,
  UserRoundX,
  Users,
} from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import {
  OverlappingTool_,
  useOverlappingTools,
} from 'src/api/db/apiOverlappingTools'
import { useToolsApi } from 'src/api/db/apiTools'
import { queryKeys } from 'src/api/db/queryKeys'
import { useGenerateOverlappingTools } from 'src/api/server/useGenerateOverlappingTools'
import { paths } from 'src/routes/paths'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, ScrollArea, Tooltip } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import { Progress } from 'src/shared/components/ui/progress'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'

export const CostReduction = () => {
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tools_ = useMemo(() => tools, [tools])
  const navigate = useNavigate()
  const generateOverlappingTools = useGenerateOverlappingTools()
  const { overlappingTools } = useOverlappingTools()
  const queryClient = useQueryClient()

  const OverlappingTool = ({ tool }: { tool: OverlappingTool_ }) => {
    const overlappingTools: any = tool.overlappingtools

    return (
      <Card className="h-[220px] p-4 ">
        <h1 className="text-sm  text-gray-700">{tool.title}</h1>

        <ScrollArea className="h-[40px] mt-2">
          <Row className="gap-2 mt-2 w-fit flex-wrap">
            {overlappingTools?.map((tool) => (
              <Badge className="bg-gradient-to-r from-blue-500 to-purple-500 text-white border-none">
                {tool}
              </Badge>
            ))}
          </Row>
        </ScrollArea>

        <ScrollArea className="h-[100px] mt-2">
          <p className="text-sm text-muted-foreground">{tool.description}</p>
        </ScrollArea>
      </Card>
    )
  }

  const SeatUtilization = () => {
    const tools = tools_?.tools
      ?.filter((tool) => tool.is_tracking)
      .filter((tool) => {
        // @ts-ignore
        const latestReceipt = tool?.sender?.receipt.sort((a, b) =>
          b.date_of_invoice!.localeCompare(a.date_of_invoice!)
        )[0]

        const userCount = tool.calculated?.userCount
        const numberOfSeats = latestReceipt?.number_of_seats || 0
        const utilization = (userCount / numberOfSeats) * 100

        return (
          tool.status === 'in_stack' &&
          latestReceipt?.pricing_model === 'PER_SEAT' &&
          utilization < 100
        )
      })

    if (!tools?.length) {
      return (
        <Card>
          <Row className="items-center gap-2">
            <Users className="w-5 h-5" />
            <p className="text-lg ">Seat utilization</p>
          </Row>

          <Row className="gap-2 items-center mt-4">
            <AlertCircle className="h-4 w-4 text-muted-foreground" />

            <p className="text-sm text-muted-foreground">
              There are no tools with low seat utilization
            </p>
          </Row>
        </Card>
      )
    }

    return (
      <Card>
        <Row className="items-center gap-2">
          <Users className="w-5 h-5" />
          <p className="text-lg ">Seat utilization</p>
        </Row>

        <ScrollArea className={`h-[${tools?.length > 3 ? 350 : 250}px]`}>
          <Table className=" mt-4 rounded-md">
            <TableBody>
              <TableRow className=" sticky top-0 z-10">
                <TableCell className="">Tool</TableCell>
                <TableCell className="">Seat utilization</TableCell>
                <TableCell className="">Monthly waste</TableCell>
                <TableCell className="">Annual waste</TableCell>
              </TableRow>

              {tools?.map((tool) => {
                // @ts-ignore
                const latestReceipt = tool?.sender?.receipt.sort((a, b) =>
                  b.date_of_invoice!.localeCompare(a.date_of_invoice!)
                )[0]

                const userCount = tool.calculated?.userCount
                const pricePerSeat = latestReceipt?.price_per_seat || 0
                const numberOfSeats = latestReceipt?.number_of_seats || 0
                const utilization = Math.round(
                  (userCount / numberOfSeats) * 100
                )
                const monthlyWaste = (numberOfSeats - userCount) * pricePerSeat

                return (
                  <TableRow className="">
                    <TableCell
                      className=" cursor-pointer"
                      onClick={() =>
                        navigate(paths.software_table + '/' + tool.id)
                      }
                    >
                      <Row className="items-center gap-2">
                        <SaasIcon
                          toolName={tool.vendor.name}
                          src={tool.vendor.logo_url || ''}
                          size="sm"
                        />
                        {tool.vendor.name}
                      </Row>
                    </TableCell>

                    <TableCell className="">
                      <Row className="items-center gap-2">
                        <Progress
                          value={utilization}
                          indicatorColor={
                            utilization >= 100
                              ? 'bg-green-500'
                              : utilization >= 50
                              ? 'bg-orange-500'
                              : 'bg-red-500'
                          }
                        />
                        <p className="text-sm">
                          {userCount}/{numberOfSeats}
                        </p>
                      </Row>
                    </TableCell>

                    <TableCell className="">
                      ${Math.abs(monthlyWaste).toFixed(2)}
                    </TableCell>

                    <TableCell className="">
                      ${(Math.abs(monthlyWaste) * 12).toFixed(2)}
                    </TableCell>
                  </TableRow>
                )
              })}

              <TableRow className="">
                <TableCell className=" text-muted-foreground">Total</TableCell>

                <TableCell className=""></TableCell>

                <TableCell className="">
                  <Badge className=" text-red-800">
                    ${tools_?.calculated?.totalMonthlyWasteSeatUtilization}
                  </Badge>
                </TableCell>

                <TableCell className="">
                  <Badge className=" text-red-800">
                    ${tools_?.calculated?.totalAnnualWasteSeatUtilization}
                  </Badge>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ScrollArea>
      </Card>
    )
  }

  const LowUserCount = () => {
    const tools = tools_?.tools
      ?.filter((tool) => tool.is_tracking)
      .filter((tool) => {
        const active_users = tool.calculated?.userCount
        const total_users = tools_?.calculated?.totalActiveUsers || 0
        const utilization = (active_users / total_users) * 100

        return tool.status === 'in_stack' && utilization < 50
      })

    if (!tools?.length) {
      return (
        <Card>
          <Row className="items-center gap-2">
            <UserRoundX className="w-5 h-5" />
            <p className="text-lg ">Low user count</p>
            <Badge variant="secondary">&lt;50% of employees are using</Badge>
          </Row>

          <Row className="gap-2 items-center mt-4">
            <AlertCircle className="h-4 w-4 text-muted-foreground" />

            <p className="text-sm text-muted-foreground">
              There are no tools with low user count
            </p>
          </Row>
        </Card>
      )
    }

    return (
      <Card className="w-full">
        <Row className="items-center gap-2">
          <UserRoundX className="w-5 h-5" />
          <p className="text-lg ">Low user count</p>
          <Badge variant="secondary">&lt;50% of employees are using</Badge>
        </Row>

        <ScrollArea className={`h-[${tools?.length > 3 ? 350 : 300}px]`}>
          <Table className=" mt-4 rounded-md">
            <TableBody>
              <TableRow className=" sticky top-0 z-10">
                <TableCell className="">Tool</TableCell>
                <TableCell className="">Active users</TableCell>
                <TableCell className="">Monthly waste</TableCell>
                <TableCell className="">Annual waste</TableCell>
              </TableRow>

              {tools
                ?.sort((a, b) => {
                  const a_users = a.calculated?.userCount
                  const b_users = b.calculated?.userCount
                  const total = tools_?.calculated?.totalActiveUsers || 0
                  const a_util = (a_users / total) * 100
                  const b_util = (b_users / total) * 100
                  return a_util - b_util
                })
                .map((tool) => {
                  const active_users = tool.calculated?.userCount
                  const total_users = tools_?.calculated?.totalActiveUsers || 0
                  const utilization = Math.round(
                    (active_users / total_users) * 100
                  )

                  return (
                    <TableRow className="">
                      <TableCell
                        className=" cursor-pointer"
                        onClick={() =>
                          navigate(paths.software_table + '/' + tool.id)
                        }
                      >
                        <Row className="items-center gap-2">
                          <SaasIcon
                            toolName={tool?.name || ''}
                            src={tool?.org_vendor?.logo_url || ''}
                            size="sm"
                          />
                          {tool?.name}
                        </Row>
                      </TableCell>

                      <TableCell className="">
                        <Row className="items-center gap-2">
                          <Badge className=" text-red-800">
                            {utilization}% ({active_users})
                          </Badge>
                        </Row>
                      </TableCell>

                      <TableCell className="">
                        ${tool.calculated?.priceMonth}
                      </TableCell>

                      <TableCell className="">
                        ${tool.calculated?.priceAnnual}
                      </TableCell>
                    </TableRow>
                  )
                })}

              <TableRow className="">
                <TableCell className=" text-muted-foreground">Total</TableCell>

                <TableCell className=""></TableCell>

                <TableCell className="">
                  <p className=" text-red-800">
                    ${tools_?.calculated?.totalMonthlyWasteLowUsage}
                  </p>
                </TableCell>

                <TableCell className="">
                  <p className=" text-red-800">
                    ${tools_?.calculated?.totalAnnualWasteLowUsage}
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ScrollArea>
      </Card>
    )
  }

  const LowUsage = () => {
    const tools = tools_?.tools
      ?.filter((tool) => tool.is_tracking)
      .filter((tool) => {
        const monthlyVisits = tool.calculated?.monthlyUserVisits
        return tool.status === 'in_stack' && monthlyVisits < 10
      })

    if (!tools?.length) {
      return (
        <Card>
          <Row className="items-center gap-2">
            <ChartColumnDecreasing className="w-5 h-5" />
            <p className="text-lg ">Low usage</p>
            <Badge variant="secondary">&lt;10 total visits/month</Badge>
          </Row>

          <Row className="gap-2 items-center mt-4">
            <AlertCircle className="h-4 w-4 text-muted-foreground" />

            <p className="text-sm text-muted-foreground">
              There are no tools with low usage
            </p>
          </Row>
        </Card>
      )
    }

    return (
      <Card className="w-full">
        <Row className="items-center gap-2">
          <ChartColumnDecreasing className="w-5 h-5" />
          <p className="text-lg ">Low usage</p>
          <Badge variant="secondary">&lt;10 total visits/month</Badge>
        </Row>

        <ScrollArea className={`h-[${tools?.length > 3 ? 350 : 300}px]`}>
          <Table className=" mt-4 rounded-md">
            <TableBody>
              <TableRow className=" sticky top-0 z-10">
                <TableCell className="">Tool</TableCell>
                <TableCell className="">Active users</TableCell>
                <TableCell className="">Monthly waste</TableCell>
                <TableCell className="">Annual waste</TableCell>
              </TableRow>

              {tools
                ?.filter((tool) => tool.calculated?.monthlyUserVisits < 10)
                .sort((a, b) => {
                  const a_visits: any = a.calculated?.monthlyUserVisits
                  const b_visits: any = b.calculated?.monthlyUserVisits
                  return a_visits - b_visits
                })
                .map((tool) => {
                  return (
                    <TableRow className="">
                      <TableCell
                        className=" cursor-pointer"
                        onClick={() =>
                          navigate(paths.software_table + '/' + tool.id)
                        }
                      >
                        <Row className="items-center gap-2">
                          <SaasIcon
                            toolName={tool.org_vendor.name}
                            src={tool.org_vendor.logo_url || ''}
                            size="sm"
                          />
                          {tool.org_vendor.name}
                        </Row>
                      </TableCell>

                      <TableCell className="">
                        <Row className="items-center gap-2">
                          <Badge className="text-red-800">
                            {tool.calculated?.monthlyUserVisits} visits
                          </Badge>
                        </Row>
                      </TableCell>

                      <TableCell className="">
                        ${tool.calculated?.priceMonth}
                      </TableCell>

                      <TableCell className="">
                        ${tool.calculated?.priceAnnual}
                      </TableCell>
                    </TableRow>
                  )
                })}

              <TableRow className="">
                <TableCell className=" text-muted-foreground">Total</TableCell>

                <TableCell className=""></TableCell>

                <TableCell className="">
                  <p className=" text-red-800">
                    ${tools_?.calculated?.totalMonthlyWasteLowUsage}
                  </p>
                </TableCell>

                <TableCell className="">
                  <p className=" text-red-800">
                    ${tools_?.calculated?.totalAnnualWasteLowUsage}
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ScrollArea>
      </Card>
    )
  }

  const OverlappingTools = () => {
    if (!overlappingTools?.data?.length) {
      return (
        <Card>
          <Row className="items-center gap-2">
            <Blend className="w-5 h-5" />
            <p className="text-lg ">Overlapping tools</p>

            <Tooltip content="Generate insights" delayDuration={0}>
              <Button
                variant="light"
                size="icon"
                className="ml-auto"
                disabled={tools_?.calculated?.totalActiveUsers === 0}
                isLoading={generateOverlappingTools.isLoading}
                onClick={async () => {
                  await generateOverlappingTools
                    .mutateAsync({
                      organization_id: orgId,
                    })
                    .then(() => {
                      queryClient.invalidateQueries({
                        queryKey: [queryKeys.overlapping_tools],
                      })
                    })
                }}
              >
                <RefreshCcw className="h-4 w-4" />
              </Button>
            </Tooltip>
          </Row>

          <Row className="gap-2 items-center mt-4">
            <AlertCircle className="h-4 w-4 text-muted-foreground" />

            <p className="text-sm text-muted-foreground">
              Press the button to the right to generate insights.
            </p>
          </Row>
        </Card>
      )
    }

    return (
      <Card>
        <Row className="items-center gap-2">
          <Blend className="w-5 h-5" />
          <p className="text-lg ">Overlapping tools</p>

          <Tooltip content="Generate insights" delayDuration={0}>
            <Button
              variant="light"
              className="ml-auto"
              isLoading={generateOverlappingTools.isLoading}
              onClick={async () => {
                await generateOverlappingTools
                  .mutateAsync({
                    organization_id: orgId,
                  })
                  .then(() => {
                    queryClient.invalidateQueries({
                      queryKey: [queryKeys.overlapping_tools],
                    })
                  })
              }}
            >
              <RefreshCcw className="h-4 w-4" />
            </Button>
          </Tooltip>
        </Row>

        <ScrollArea className="h-[240px]">
          <div className="grid grid-cols-3 gap-4 mt-4">
            {overlappingTools?.data?.map((tool) => (
              <OverlappingTool key={tool.id} tool={tool} />
            ))}
          </div>
        </ScrollArea>
      </Card>
    )
  }

  return (
    <Column className="gap-4 w-full">
      <SeatUtilization />

      <div className="grid grid-cols-2 gap-2">
        <LowUsage />
        <LowUserCount />
      </div>

      <OverlappingTools />
    </Column>
  )
}
