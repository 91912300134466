import dayjs from 'dayjs'
import { Check, Coins, CreditCard, HelpingHand } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useSendReceipts } from 'src/api/server/useSendReceipts'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Image,
  Input,
  ScrollArea,
} from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'src/shared/components/ui/dialog'
import { cn } from 'src/shared/components/ui/utils'

export const SendReceiptsDialog = ({
  filteredReceipts,
  emailDialogOpen,
  setEmailDialogOpen,
}: {
  filteredReceipts: any[]
  emailDialogOpen: boolean
  setEmailDialogOpen: (open: boolean) => void
}) => {
  const sendReceipts = useSendReceipts()

  const [selectedReceipts, setSelectedReceipts] = useState<any[]>([])
  const [emailTo, setEmailTo] = useState(
    () => localStorage.getItem('emailTo') || ''
  )
  const handleSendEmails = () => {
    sendReceipts.mutate({
      fromEmail: emailTo,
      toEmail: emailTo,
      fileUrls: filteredReceipts
        ?.map((receipt) => receipt.receipt_file || '')
        .filter(Boolean) as string[],
    })

    localStorage.setItem('emailTo', emailTo)
    setEmailDialogOpen(false)
  }

  useEffect(() => {
    if (filteredReceipts) {
      setSelectedReceipts(filteredReceipts)
    }
  }, [filteredReceipts])

  return (
    <Dialog open={emailDialogOpen} onOpenChange={setEmailDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Send Receipts</DialogTitle>

          <DialogDescription>
            Enter an email address to send the filtered receipts to.
          </DialogDescription>
        </DialogHeader>

        <Input
          placeholder="Email address"
          value={emailTo}
          onChange={(e) => setEmailTo(e.target.value)}
        />

        <ScrollArea className="w-full h-[300px] mt-4">
          <Column className="w-full h-full">
            <div className="grid grid-cols-2 gap-2 w-full">
              {filteredReceipts?.map((receipt) => (
                <Card
                  key={receipt.id}
                  className="p-4 rounded-lg relative cursor-pointer"
                  onClick={() => {
                    if (selectedReceipts.includes(receipt)) {
                      setSelectedReceipts(
                        selectedReceipts.filter((r) => r !== receipt)
                      )
                    } else {
                      setSelectedReceipts([...selectedReceipts, receipt])
                    }
                  }}
                >
                  <Column className="p-2 pb-6 gap-[5px] absolute top-0 right-0 bg-gradient-to-b to-transparent from-black/70 w-full rounded-lg">
                    <Badge className="w-fit rounded-full">
                      <HelpingHand className="w-4 h-4 mr-2" />
                      {receipt.sender?.name}
                    </Badge>

                    <Badge className="w-fit rounded-full">
                      <Coins className="w-4 h-4 mr-2" />
                      {`${receipt.total_cost} ${receipt.currency}`}
                    </Badge>

                    {receipt.due_date && (
                      <Badge className="w-fit rounded-full">
                        <CreditCard className="w-4 h-4 mr-2" />
                        Due {dayjs(receipt.due_date).fromNow()}
                      </Badge>
                    )}

                    {receipt.type === 'software' && (
                      <Badge className="w-fit rounded-full">
                        <CreditCard className="w-4 h-4 mr-2" />
                        Software
                      </Badge>
                    )}
                  </Column>

                  <Image
                    src={receipt.receipt_file || ''}
                    alt="receipt"
                    width={200}
                    className="w-full h-[130px] object-cover rounded-lg"
                  />

                  <div
                    className={cn(
                      'absolute bottom-3 right-3  rounded-full p-1 border border-black/10 h-7 w-7 flex items-center justify-center',
                      selectedReceipts.includes(receipt)
                        ? 'bg-green-400'
                        : 'bg-white/70'
                    )}
                  >
                    {selectedReceipts.includes(receipt) && (
                      <Check className={cn('w-4 h-4 text-white')} />
                    )}
                  </div>
                </Card>
              ))}
            </div>
          </Column>
        </ScrollArea>

        <Row className="gap-2 justify-end mt-2">
          <Button variant="secondary" onClick={() => setEmailDialogOpen(false)}>
            Cancel
          </Button>

          <Button onClick={handleSendEmails}>
            Send {selectedReceipts?.length} receipts
          </Button>
        </Row>
      </DialogContent>
    </Dialog>
  )
}
