import { UseFormReturn } from 'react-hook-form'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/shared/components/ui/form'
import { Input } from 'src/shared/components/ui/input'

export const FormInput = ({
  name,
  label,
  description,
  methods,
  placeholder,
  endContent,
  disabled = false,
  type = 'text',
  className = '',
  appendEndContent,
}: {
  name: string
  label?: string
  description?: string
  methods: UseFormReturn<any>
  placeholder?: string
  endContent?: React.ReactNode
  disabled?: boolean
  type?: string
  className?: string
  appendEndContent?: React.ReactNode
}) => {
  return (
    <FormField
      control={methods.control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel className="text-xs text-muted-foreground ">
            {label}
          </FormLabel>
          <FormControl>
            <Input
              {...field}
              placeholder={placeholder}
              endContent={endContent}
              disabled={disabled}
              type={type}
              appendEndContent={appendEndContent}
            />
          </FormControl>

          {description && (
            <FormDescription className="text-xs text-muted-foreground">
              {description}
            </FormDescription>
          )}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
