import { Column } from '@tanstack/react-table'
import { ArrowDown, ArrowDownUp, ArrowUp } from 'lucide-react'
import { Link } from 'react-router-dom'
import { paths } from 'src/routes/paths'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Row } from 'src/shared/components/Semantic/all'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'

import { Application } from './consts'

// const TypeMap = {
//   software: (
//     <Row className="gap-2 items-center">
//       <Laptop className="w-4 h-4" /> Software
//     </Row>
//   ),
//   service: (
//     <Row className="gap-2 items-center">
//       <HandHelping className="w-4 h-4" /> Service
//     </Row>
//   ),
//   other: (
//     <Row className="gap-2 items-center">
//       <Code className="w-4 h-4" /> Other
//     </Row>
//   ),
// }

const SortableHeader = ({
  column,
  children,
}: {
  column: Column<Application, unknown>
  children: React.ReactNode
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Row
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          className="cursor-pointer items-center"
        >
          {children}
          {column.getIsSorted() === 'asc' ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : column.getIsSorted() === 'desc' ? (
            <ArrowDown className="ml-2 h-4 w-4" />
          ) : (
            <ArrowDownUp className="ml-2 h-4 w-4" />
          )}
        </Row>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start">
        <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
          Sort Ascending
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
          Sort Descending
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const useColumns = () => {
  return [
    {
      accessorKey: 'app',
      header: ({ column }) => (
        <SortableHeader column={column}>Application</SortableHeader>
      ),
      cell: ({ row }) => (
        <Link
          className="cursor-pointer flex items-center gap-2 my-2"
          to={`${paths.software_table}/${row.original.app.id}`}
        >
          <SaasIcon
            toolName={row.original.app.name}
            src={row.original.app.logo_url || ''}
            size="sm"
          />
          {row.original.app.name}
        </Link>
      ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.app.name.localeCompare(rowB.original.app.name)
      },
      accessorFn: (row) => row.app.name,
    },
    {
      accessorKey: 'dept',
      header: ({ column }) => (
        <SortableHeader column={column}>Department</SortableHeader>
      ),
      cell: ({ row }) => <div>{row.getValue('dept')}</div>,
    },
    {
      accessorKey: 'owner',
      header: ({ column }) => (
        <SortableHeader column={column}>Owner</SortableHeader>
      ),
      cell: ({ row }) => <div>{row.getValue('owner')}</div>,
    },
    {
      accessorKey: 'spendMonthly',
      header: ({ column }) => (
        <SortableHeader column={column}>Monthly spend</SortableHeader>
      ),
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spendMonthly'))
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend)
        return <div className="text-right ">{spend ? formatted : ''}</div>
      },
    },
    {
      accessorKey: 'spendAnnual',
      header: ({ column }) => (
        <SortableHeader column={column}>Annual spend</SortableHeader>
      ),
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spendAnnual'))
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend)
        return <div className="text-right ">{spend ? formatted : ''}</div>
      },
    },
    {
      accessorKey: 'tracking_usage',
      header: ({ column }) => (
        <SortableHeader column={column}>Usage</SortableHeader>
      ),
      cell: ({ row }) => (
        <div className="capitalize flex items-center gap-2">
          {row.getValue('tracking_usage') ? (
            <>
              <div className="h-2 w-2 text-green-500 rounded-full bg-green-500" />
              <span>Tracking</span>
            </>
          ) : (
            <>
              <div className="h-2 w-2 text-red-500 rounded-full bg-red-500" />
              <span>No tracking</span>
            </>
          )}
        </div>
      ),
    },
    {
      accessorKey: 'tracking_cost',
      header: ({ column }) => (
        <SortableHeader column={column}>Cost</SortableHeader>
      ),
      cell: ({ row }) => (
        <div className="capitalize flex items-center gap-2">
          {row.getValue('tracking_cost') ? (
            <>
              <div className="h-2 w-2 text-green-500 rounded-full bg-green-500" />
              <span>Tracking</span>
            </>
          ) : (
            <>
              <div className="h-2 w-2 text-red-500 rounded-full bg-red-500" />
              <span>Not tracking</span>
            </>
          )}
        </div>
      ),
    },
  ]
}
