import { createBrowserRouter } from 'react-router-dom'
import { CostsPage } from 'src/pages/Costs'
import { ExtensionPage } from 'src/pages/Extension'
import { ExtensionLoginPage } from 'src/pages/ExtensionLogin'
import { CookieConsent } from 'src/pages/GDPR/CookieConsent'
import { PrivacyPolicy } from 'src/pages/GDPR/PrivacyPolicy'
import { TermsOfService } from 'src/pages/GDPR/TermsOfService'
import { LoginSignup } from 'src/pages/LoginSignup'
import { OrganizationPage } from 'src/pages/Organization'
import { ReceiptsPage } from 'src/pages/Receipts'
import { SubscriptionPage } from 'src/pages/Software'
import { InsightsSubscriptionsPage } from 'src/pages/Software_Insights'
import { SubscriptionsPage } from 'src/pages/Software_Table'
import { UntrackedTablePage } from 'src/pages/Software_Untracked'
import { UserSettingsPage } from 'src/pages/UserSettings'
import { ValidateDomainsPage } from 'src/pages/ValidateDomains'
import { AppLayout } from 'src/shared/components/AppLayout'

import { ProtectedRoute } from './components/ProtectedRoute'
import { paths } from './paths'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout sidebar />,
    children: [
      {
        index: true,
        element: <ProtectedRoute component={ReceiptsPage} />,
      },
      {
        path: paths.software_table,
        element: <ProtectedRoute component={SubscriptionsPage} />,
      },
      {
        path: paths.software_table + '/:tool_id',
        element: <ProtectedRoute component={SubscriptionPage} />,
      },
      {
        path: paths.costs,
        element: <ProtectedRoute component={CostsPage} />,
      },
      {
        path: paths.software_insights,
        element: <ProtectedRoute component={InsightsSubscriptionsPage} />,
      },
      {
        path: paths.software_untracked,
        element: <ProtectedRoute component={UntrackedTablePage} />,
      },
      {
        path: paths.organization,
        element: <ProtectedRoute component={OrganizationPage} />,
      },
      {
        path: paths.receipts,
        element: <ProtectedRoute component={ReceiptsPage} />,
      },
      {
        path: paths.userSettings,
        element: <ProtectedRoute component={UserSettingsPage} />,
      },
    ],
  },
  // --- Routes without sidebar ---
  {
    path: '/',
    element: <AppLayout />,
    children: [
      // --- Protected routes ---
      {
        path: paths.loginSignUp + '/:plan?',
        element: <LoginSignup />,
      },
      {
        path: paths.validateDomains,
        element: <ValidateDomainsPage />,
      },
      // Open routes
      {
        path: paths.cookieConsent,
        element: <CookieConsent />,
      },
      {
        path: paths.privacyPolicy,
        element: <PrivacyPolicy />,
      },
      {
        path: paths.termsOfService,
        element: <TermsOfService />,
      },
      {
        path: paths.extension,
        element: <ExtensionPage />,
      },
      {
        path: `${paths.extension}/login`,
        element: <ExtensionLoginPage />,
      },
    ],
  },
])
