import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getColorFromNameByUsingHash } from 'src/shared/utils'

import { Tool_ } from '..'

dayjs.extend(relativeTime)

/**
 * @param tools Data from the tools table
 * @returns Stats for the tools
 */
export const calculateDataTotal = (tools: Tool_[]) => {
  const annualSpend = tools.reduce((acc, tool) => {
    return acc + tool.calculated?.priceAnnual
  }, 0)

  const monthlySpend = tools.reduce((acc, tool) => {
    return acc + tool.calculated?.priceMonth
  }, 0)

  const totalActiveUsers = tools.reduce((acc, tool) => {
    return acc + tool.calculated?.userCount
  }, 0)

  const totalMonthlyWasteSeatUtilization = tools
    .filter((tool) => tool.is_tracking && tool.status === 'in_stack')
    .filter((tool) => {
      // @ts-ignore
      const latestReceipt = tool?.sender?.receipt.sort((a, b) =>
        b.date_of_invoice!.localeCompare(a.date_of_invoice!)
      )[0]

      const userCount = tool.calculated?.userCount
      const numberOfSeats = latestReceipt?.number_of_seats
      const utilization = (userCount / numberOfSeats) * 100

      return latestReceipt?.pricing_model === 'PER_SEAT' && utilization < 100
    })
    .reduce((acc, tool) => {
      // @ts-ignore
      const latestReceipt = tool?.sender?.receipt.sort((a, b) =>
        b.date_of_invoice!.localeCompare(a.date_of_invoice!)
      )[0]

      const userCount = tool.calculated?.userCount
      const pricePerSeat = latestReceipt?.price_per_seat || 0
      const numberOfSeats = latestReceipt?.number_of_seats || 0
      const monthlyWaste = (numberOfSeats - userCount) * pricePerSeat

      return acc + monthlyWaste
    }, 0)

  const totalMonthlyWasteLowUsage = tools
    .filter((tool) => tool.is_tracking && tool.status === 'in_stack')
    .filter((tool) => {
      const active_users = tool.calculated?.userCount
      const utilization = (active_users / totalActiveUsers) * 100

      return (
        tool.status === 'in_stack' &&
        utilization < 50 &&
        tool.calculated?.priceMonth > 0
      )
    })
    .reduce((acc, tool) => acc + (tool.calculated?.priceMonth || 0), 0)

  const departments = [
    'Product',
    'IT',
    'Sales',
    'Marketing',
    'HR',
    'Data & Analytics',
    'Engineering',
    'Finance',
    'Customer Success',
    'Legal',
    'Other',
  ]

  const spendByCategory = departments.reduce((acc, department) => {
    acc[department] = {
      tool: department,
      cost: 0,
      fill: getColorFromNameByUsingHash(department),
    }
    return acc
  }, {})

  tools.forEach((tool) => {
    const department = tool.department || 'Other'
    if (spendByCategory[department]) {
      spendByCategory[department].cost += tool.calculated.priceAnnual
    }
  })

  return {
    annualSpend,
    monthlySpend,
    totalActiveUsers,
    totalMonthlyWasteSeatUtilization,
    totalAnnualWasteSeatUtilization: totalMonthlyWasteSeatUtilization * 12,
    totalMonthlyWasteLowUsage,
    totalAnnualWasteLowUsage: totalMonthlyWasteLowUsage * 12,
    chartData: Object.values(spendByCategory),
  }
}
