export enum ServerRoutes {
  inviteAdmins = '/inviteAdmins',
  inviteExtensionUsers = '/inviteExtensionUsers',
  deleteExtensionUser = '/deleteExtensionUser',
  addToolsManually = '/addToolsManually',
  generateOverlappingTools = '/generateOverlappingTools',
  askTeam = '/askTeam',
  sendExtensionInvite = '/sendExtensionInvite',
  scanEmailAccount = '/scanEmailAccount',
  sendReceipts = '/emailReceipts',
  googleAuth = '/auth/google',
}
