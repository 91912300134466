import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Binoculars, Clock, Pencil, User } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { useUserActivityApi } from 'src/api/db/apiUserActivity'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, ScrollArea } from 'src/shared/components/ui'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'

dayjs.extend(relativeTime)

export const Usage = () => {
  const { tool_id } = useParams()
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({
    organization_id: orgId || '',
  })
  const tool = tools?.tools?.find((t) => t.id === Number(tool_id))
  const navigate = useNavigate()

  const { userActivitiesForTool } = useUserActivityApi({
    tool_id: tool?.id,
    org_id: orgId || '',
  })

  return (
    <Column className="gap-4 w-full">
      <Card>
        <Row className="justify-between items-center">
          <p className="text-lg ">User activity</p>

          <Button
            variant="light"
            className="mt-4"
            onClick={() => navigate('#settings')}
          >
            <Pencil className="mr-2 w-4 h-4" />
            Usage settings
          </Button>
        </Row>

        <ScrollArea className="h-[60vh]">
          <Table className="mt-4 rounded-md">
            <TableBody>
              {userActivitiesForTool?.data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Row className="items-center">
                      No activity tracked yet
                      <Binoculars className="w-4 h-4 ml-2" />
                    </Row>
                  </TableCell>
                </TableRow>
              )}

              {userActivitiesForTool?.data?.map((userActivity) => (
                <TableRow className="flex flex-row justify-between">
                  <TableCell>
                    <Row className="items-center">
                      <User className="w-4 h-4 mr-2" />
                      {userActivity.org_user?.user?.email}
                    </Row>
                  </TableCell>

                  <TableCell>
                    <Row className="items-center ">
                      <Clock className="w-4 h-4 mr-2" />
                      {dayjs(userActivity.last_visited).fromNow()}
                    </Row>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </Card>
    </Column>
  )
}
