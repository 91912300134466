import { Outlet } from 'react-router-dom'

import { SidebarDemo } from '../../../routes/components/Sidebar'
import { AuthProvider } from '../../hooks/authProvider'
import { CommandCentral } from '../CommandCentral'

export function AppLayout({ sidebar }: { sidebar?: boolean }) {
  if (sidebar) {
    return (
      <AuthProvider>
        <SidebarDemo>
          <Outlet />
          <CommandCentral />
        </SidebarDemo>
      </AuthProvider>
    )
  }

  return (
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  )
}
