import { useQuery } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'

import { useOrgIdApi } from './apiOrgId'
import { queryKeys } from './queryKeys'

export const useNotifications = () => {
  const supabase = useSupabaseClient()
  const { orgId } = useOrgIdApi()

  const { data: notifications } = useQuery({
    queryKey: [queryKeys.notifications, orgId],
    queryFn: async () =>
      await supabase
        .from('notification')
        .select('*')
        .eq('organization_id', orgId)
        .order('created_at', { ascending: false }),
    enabled: !!orgId,
  })

  return {
    notifications,
  }
}
