import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ChevronDown, ChevronRight, Crown } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useOrgsApi } from 'src/api/db/apiOrgs'
import { useUsersApi } from 'src/api/db/apiUsers'
import { paths } from 'src/routes/paths'
import { OnboardingWidgetNew } from 'src/shared/components/OnboardingWidget'
import { Column } from 'src/shared/components/Semantic/all'
import { Separator } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import { Progress } from 'src/shared/components/ui/progress'
import { cn } from 'src/shared/components/ui/utils'
import { getSubscriptionName } from 'src/shared/consts'

import { OrgDialog } from './components/OrgDialog'
import { useLinks } from './consts'
import {
  Sidebar,
  SidebarBody,
  SidebarLink,
  SidebarLinkChildren,
} from './sidebar'

dayjs.extend(relativeTime)

const avatar =
  'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.BitMB_DGEBBIrVLqrvTnBQHaFP%26pid%3DApi&f=1&ipt=cb39a1219b1bd344fdee9352549c337d5ab3980b13cf2037e9d1b71154a2a2da&ipo=images'

export function SidebarDemo({ children }: { children: React.ReactNode }) {
  const { orgUser } = useOrgUsersApi()
  const [onboarded, setOnboarded] = useState(true)
  const { user } = useUsersApi()
  const [expandedLinks, setExpandedLinks] = useState<{
    [key: string]: boolean
  }>({})
  const location = useLocation()
  const links = useLinks()
  const navigate = useNavigate()
  const { organization } = useOrgsApi({
    orgId: orgUser?.data?.organization?.id,
  })

  useEffect(() => {
    setOnboarded(user?.data?.onboarded ?? true)
  }, [user?.data?.onboarded])

  const daysFromCreation = dayjs().diff(
    dayjs(orgUser?.data?.user?.created_at),
    'days'
  )
  const daysLeft = 10 - daysFromCreation

  const toggleExpand = (idx: number) => {
    setExpandedLinks((prev) => ({
      ...prev,
      [idx]: !prev[idx],
    }))
  }

  return (
    <>
      <div
        // This wraps the whole app
        className={cn(
          'flex flex-col md:flex-row w-full flex-1 mx-auto overflow-hidden',
          'h-screen',
          'bg-[url(https://i.ibb.co/yFQPgwH/Ska-rmavbild-2025-01-19-kl-13-32-56.png)] bg-cover bg-center'
        )}
      >
        <Sidebar>
          <SidebarBody className="justify-between gap-10">
            <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
              <OrgDialog avatar={avatar} orgUser={orgUser} />

              <Separator className="my-2 bg-white/10" />

              <div className="mt-2 flex flex-col gap-2">
                {links.map((link, idx) => {
                  const isActive =
                    location.pathname === link.href ||
                    (location.pathname.includes('software') &&
                      link.href.includes('software'))

                  return (
                    <div key={idx}>
                      <div
                        onClick={() => {
                          link?.children && toggleExpand(idx)
                          navigate(link.href)
                        }}
                        className={cn(
                          'cursor-pointer flex items-center p-1 border border-transparent rounded-[30px] hover:glass pl-3 transition-all duration-50 active:scale-95',
                          isActive ? 'glass' : ''
                        )}
                      >
                        <SidebarLink link={link} isActive={isActive} />

                        {link?.children &&
                          (expandedLinks[idx] ? (
                            <ChevronDown className="h-4 w-4 ml-auto mr-2" />
                          ) : (
                            <ChevronRight className="h-4 w-4 ml-auto mr-2" />
                          ))}
                      </div>

                      {link?.children && expandedLinks[idx] && (
                        <div className="ml-4 mt-2 flex flex-col gap-2">
                          {link?.children.map((childLink, childIdx) => (
                            <div key={childIdx}>
                              <SidebarLinkChildren link={childLink} />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>

            {!onboarded && <OnboardingWidgetNew />}

            <div>
              {daysLeft > 0 && (
                <Column className="gap-2 mb-3">
                  <p className="text-xs text-neutral-500">
                    {daysLeft} days left of trial
                  </p>

                  <Progress
                    value={(daysLeft / 10) * 100}
                    indicatorColor="bg-blue-600"
                    className="h-2"
                  />
                </Column>
              )}

              <Badge
                className="py-1 px-2 rounded-full cursor-pointer"
                onClick={() => navigate(`${paths.organization}?tab=settings`)}
              >
                <Crown className="h-3 w-3 text-yellow-500 mr-1" />
                {getSubscriptionName(organization?.data?.stripe_product_id)}
              </Badge>
            </div>
          </SidebarBody>
        </Sidebar>

        {/* The main layout container */}
        <div className="flex h-full flex-col flex-1 py-8 p-10 overflow-y-auto">
          {children}
        </div>
      </div>
    </>
  )
}
