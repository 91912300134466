import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
  Ban,
  Check,
  Coins,
  ExternalLink,
  MessageCircleQuestion,
  Pencil,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { Tool_, useToolsApi } from 'src/api/db/apiTools'
import { queryKeys } from 'src/api/db/queryKeys'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, Image, Tooltip } from 'src/shared/components/ui'
import { Alert } from 'src/shared/components/ui/alert'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'
import { Tabs, TabsList, TabsTrigger } from 'src/shared/components/ui/tabs'

import { AddSubscriptionSheet } from '../Software/tabs/Costs/AddSubscriptionSheet'
import { EditSubscriptionSheet } from '../Software/tabs/Costs/EditSubscriptionSheet'

dayjs.extend(relativeTime)

function dedupeArray(array?: any[]) {
  const seen = new Set()
  return array
    ?.filter((item) => {
      const uniqueId = item?.tool?.id // Using tool.id for deduplication
      if (seen.has(uniqueId)) {
        return false // Skip if already seen
      }
      seen.add(uniqueId)
      return true // Include if not seen
    })
    .filter((item) => item?.tool)
}

export const ValidateDomainsPage = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { orgId } = useOrgIdApi()
  const { orgUser } = useOrgUsersApi()
  const { updateTool } = useToolsApi({ organization_id: orgId || '' })
  const { userTools } = useToolsApi({
    organization_id: orgId || '',
    org_user_id: orgUser?.data?.id,
  })

  const [isAddSubscriptionSheetOpen, setIsAddSubscriptionSheetOpen] =
    useState(false)
  const [isEditSubscriptionSheetOpen, setIsEditSubscriptionSheetOpen] =
    useState(false)
  const [editReceiptId, setEditReceiptId] = useState()

  const dedupedTools = dedupeArray(userTools?.data || [])

  useEffect(() => {
    setTimeout(() => {
      userTools.refetch()
    }, 2000)
  }, [isAddSubscriptionSheetOpen])

  const ToolSwitch = ({ tool }: { tool: Tool_ }) => {
    return (
      <Tabs
        defaultValue={tool?.status_should_be || 'not_set'}
        onValueChange={async (value) => {
          await updateTool
            .mutateAsync({
              id: tool.id,
              status_should_be: value as 'tracked' | 'untracked',
            })
            .then(() => {
              queryClient.invalidateQueries({
                queryKey: [queryKeys.userTools],
              })
            })
        }}
      >
        <TabsList className="w-fit">
          <TabsTrigger value="not_set">
            <Tooltip content="Waiting for response" delayDuration={100}>
              <MessageCircleQuestion className="w-4 h-4 text-gray-500" />
            </Tooltip>
          </TabsTrigger>

          <TabsTrigger value="untracked">
            <Tooltip content="Not Using" delayDuration={100}>
              <Ban className="w-4 h-4 text-red-500" />
            </Tooltip>
          </TabsTrigger>

          <TabsTrigger value="tracked">
            <Tooltip content="I'm using" delayDuration={100}>
              <Check className="w-4 h-4 text-green-500" />
            </Tooltip>
          </TabsTrigger>
        </TabsList>
      </Tabs>
    )
  }

  return (
    <>
      <Column className="gap-4 w-full h-[100vh] items-center justify-center">
        <Card className="m-8">
          <p className="text-lg ">Which tools should we track?</p>

          <p className="text-sm text-gray-500">
            These tools are currently not being tracked, but it looks like you
            might be using them.
          </p>

          <div className="overflow-auto h-[calc(100vh-300px)] mt-4">
            <Table>
              <TableBody>
                {!dedupedTools?.length && (
                  <TableRow>
                    <TableCell className="h-24">
                      <Alert className="w-fit mx-auto my-4">
                        No tools to validate. Done!
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}

                {dedupedTools
                  ?.filter(({ tool }) => tool.vendor?.status !== 'blocked')
                  ?.sort(
                    (a, b) =>
                      b.tool?.created_at.localeCompare(a.tool?.created_at)
                  )
                  .map(({ tool }: { tool: Tool_ }) => {
                    return (
                      <>
                        <TableRow key={tool?.id} className="bg-gray-50/80">
                          <TableCell>
                            <Row className="flex items-center gap-3">
                              <SaasIcon
                                toolName={tool?.vendor?.name}
                                src={tool?.vendor?.logo_url || ''}
                                size="lg"
                              />

                              <Column>
                                <h2
                                  className="text-sm  cursor-pointer"
                                  onClick={() =>
                                    window.open(
                                      tool?.vendor?.url ?? '',
                                      '_blank'
                                    )
                                  }
                                >
                                  <Row>
                                    {tool?.vendor?.name}
                                    <ExternalLink className="w-4 h-4 ml-2" />
                                  </Row>
                                </h2>

                                <span className="text-xs ">
                                  {tool?.vendor?.root_domain}
                                </span>
                              </Column>
                            </Row>
                          </TableCell>

                          <TableCell>
                            <Column>
                              <span className="text-xs ">
                                You used this tool{' '}
                                {dayjs(tool?.vendor?.created_at).fromNow()}
                              </span>

                              <span className="text-xs ">
                                (
                                {dayjs(tool?.vendor?.created_at).format(
                                  'MMM D, YYYY'
                                )}
                                )
                              </span>
                            </Column>
                          </TableCell>

                          <TableCell className="w-[150px]">
                            {tool?.status_should_be === 'tracked' &&
                              // @ts-ignore
                              tool?.receipt?.length === 0 && (
                                <Button
                                  onClick={() => {
                                    navigate('#' + tool.id)
                                    setIsAddSubscriptionSheetOpen(true)
                                  }}
                                >
                                  <Coins className="w-4 h-4 mr-2" />
                                  Add cost
                                </Button>
                              )}

                            {tool?.status_should_be === 'tracked' &&
                              // @ts-ignore
                              tool?.receipt?.length > 0 && (
                                <Button
                                  onClick={() => {
                                    setEditReceiptId(
                                      // @ts-ignore
                                      tool?.receipt?.[0]?.id
                                    )
                                    setIsEditSubscriptionSheetOpen(true)
                                  }}
                                >
                                  <Pencil className="w-4 h-4 mr-2" />
                                  Edit cost
                                </Button>
                              )}
                          </TableCell>

                          <TableCell className="text-right">
                            <ToolSwitch tool={tool} />
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  })}
              </TableBody>
            </Table>
          </div>

          <Button
            variant="ghost"
            className="mt-4"
            onClick={() => window.open('https://pinn.one/', '_blank')}
          >
            <Image
              src={
                'https://framerusercontent.com/images/FdmnfWlHkzs04NXkQtgWqsgtq8s.png?scale-down-to=512'
              }
              alt="Logo"
              className="w-[100px]"
            />
          </Button>
        </Card>
      </Column>

      <AddSubscriptionSheet
        isOpen={isAddSubscriptionSheetOpen}
        setIsOpen={setIsAddSubscriptionSheetOpen}
      />

      <EditSubscriptionSheet
        isOpen={isEditSubscriptionSheetOpen}
        setIsOpen={setIsEditSubscriptionSheetOpen}
        receipt_id={editReceiptId!}
      />
    </>
  )
}
