import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import { Calendar } from 'src/shared/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/shared/components/ui/popover'
import { cn } from 'src/shared/components/ui/utils'

export const DatePickerInbox = ({
  date,
  setDate,
  placeholder,
  label,
}: {
  date: Date | undefined
  setDate: (date: Date | undefined) => void
  placeholder: string
  label: string
}) => (
  <Column className="gap-1">
    <Popover>
      <PopoverTrigger>
        <Row className="gap-1 items-center">
          <p className="text-xs text-muted-foreground">{label}</p>

          <Button
            variant={'ghost'}
            className={cn(
              'w-fit justify-start text-left  px-2 py-1',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />

            {date ? (
              format(date, 'MMM do, yyyy')
            ) : (
              <span className="text-muted-foreground text-xs">
                {placeholder}
              </span>
            )}
          </Button>
        </Row>
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(date) => setDate(date || new Date())}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  </Column>
)
