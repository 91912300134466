import { zodResolver } from '@hookform/resolvers/zod'
import { Chrome, Pencil, Plus, PlusCircle, Receipt } from 'lucide-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useOrgVendors } from 'src/api/db/apiOrgVendors'
import { useSendersApi } from 'src/api/db/apiSenders'
import { useToolsApi } from 'src/api/db/apiTools'
import {
  FormCheckbox,
  FormInput,
  FormSelect,
  FormTextarea,
  FormWrapper,
} from 'src/shared/components/FormComponents'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Dialog, DialogContent, ScrollArea } from 'src/shared/components/ui'
import { Button } from 'src/shared/components/ui/button'
import { DialogTrigger } from 'src/shared/components/ui/dialog'
import { departmentOptions } from 'src/shared/consts'
import { z } from 'zod'

const formSchema = z.object({
  organization_id: z.number(),
  org_vendor_id: z.string(),
  sender_id: z.string(),
  owner_org_user_id: z.number(),

  name: z.string(),
  description: z.string(),
  department: z.string(),
  website: z.string(),

  type: z.string(),
  status: z.string(),
  is_tracking: z.boolean(),
  root_domain: z.string(),
  link_to_pricing_page: z.string(),
})

export const AddSubscriptionDialog = ({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean
  setIsModalOpen: (open: boolean) => void
}) => {
  const { orgId } = useOrgIdApi()
  const { addTool } = useToolsApi({})
  const { orgVendors, updateOrgVendor } = useOrgVendors()
  const { senders } = useSendersApi({ orgId })
  const { orgUser } = useOrgUsersApi()

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    methods.reset()
  }, [isModalOpen])

  const handleSubmit = async () => {
    const values = methods.getValues()

    if (values.org_vendor_id) {
      await updateOrgVendor.mutateAsync({
        id: Number(values.org_vendor_id),
        status: 'in_stack',
      })
    }

    await addTool.mutateAsync({
      organization_id: orgId,
      org_vendor_id: Number(values.org_vendor_id),
      sender_id: Number(values.sender_id),
      owner_org_user_id: orgUser?.data?.id,

      name: values.name,
      description: values.description,
      department: values.department,
      website: values.website,

      type: values.type,
      status: 'in_stack',
      is_tracking: values.is_tracking,
      root_domain: values.root_domain,
      link_to_pricing_page: values.link_to_pricing_page,
    })
    methods.reset()

    setIsModalOpen(false)
  }

  const autofillFromReceipts = () => {
    const sender = senders?.data?.find(
      (sender) => sender.id === Number(methods.getValues('sender_id'))
    )
    methods.setValue('name', sender?.name || '')
    methods.setValue('description', sender?.description || '')
    methods.setValue('department', sender?.category || '')
    methods.setValue('website', sender?.url || '')
    methods.setValue('is_tracking', false)
    methods.setValue('type', 'service')
  }

  const autofillFromExtension = () => {
    const vendor = orgVendors?.data?.find(
      (vendor) => vendor.id === Number(methods.getValues('org_vendor_id'))
    )
    methods.setValue('name', vendor?.name || '')
    methods.setValue('description', vendor?.description || '')
    methods.setValue('department', vendor?.category || '')
    methods.setValue('website', vendor?.url || '')
    methods.setValue('is_tracking', true)
    methods.setValue('root_domain', vendor?.root_domain || '')
    methods.setValue('link_to_pricing_page', vendor?.link_to_pricing_page || '')
    methods.setValue('type', 'software')
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogTrigger asChild>
        <Button className="ml-auto">
          <PlusCircle className="mr-2 h-4 w-4" />
          Add software
        </Button>
      </DialogTrigger>

      <DialogContent className="max-w-lg" showClose={false}>
        <FormWrapper
          onSubmit={methods.handleSubmit(handleSubmit)}
          methods={methods}
        >
          <ScrollArea className="h-[500px]" scrollbarHide>
            <Column className="gap-2">
              <div className="relative w-full grid grid-cols-2 gap-6">
                <Column className=" rounded-md p-3 gap-2 glass">
                  <Row>
                    <Chrome className="mr-2 h-4 w-4 text-muted-foreground" />
                    <p className="text-xs text-muted-foreground ">
                      Track usage from extension
                    </p>
                  </Row>

                  <FormSelect
                    name="org_vendor_id"
                    options={[
                      {
                        value: '0',
                        label: 'No vendor',
                      },
                      ...(orgVendors?.data
                        ?.filter(
                          (orgVendor) =>
                            !['in_stack', 'ignored'].includes(orgVendor.status!)
                        )
                        ?.map((vendor) => ({
                          value: vendor.id.toString() || '',
                          label: (
                            <Row className="gap-2 items-center">
                              <p>{vendor.name}</p>
                              <p className="text-xs text-muted-foreground">
                                ({vendor.root_domain})
                              </p>
                            </Row>
                          ),
                        })) || []),
                    ]}
                    methods={methods}
                  />
                </Column>

                <Plus className="absolute h-4 w-4 text-muted-foreground top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />

                <Column className=" rounded-md p-3 gap-2 glass">
                  <Row>
                    <Receipt className="mr-2 h-4 w-4 text-muted-foreground" />
                    <p className="text-xs text-muted-foreground ">
                      Track cost from receipts
                    </p>
                  </Row>

                  <FormSelect
                    name="sender_id"
                    options={[
                      {
                        value: '0',
                        label: 'No sender',
                      },
                      ...(senders?.data?.map((sender) => ({
                        value: sender.id.toString() || '',
                        label: sender.name || '',
                      })) || []),
                    ]}
                    methods={methods}
                  />
                </Column>
              </div>

              <Column className="mt-2">
                {!methods.watch('sender_id') &&
                  !methods.watch('org_vendor_id') && (
                    <p className="text-sm text-muted-foreground  ml-1">
                      Choose at least one source for the subscription.
                    </p>
                  )}

                {methods.watch('sender_id') && (
                  <p className="text-sm text-muted-foreground  ml-1">
                    You will be tracking{' '}
                    <span className="font-bold text-primary">costs</span> from
                    {' ' +
                      senders?.data?.find(
                        (sender) =>
                          sender.id === Number(methods.getValues('sender_id'))
                      )?.name}
                  </p>
                )}

                {methods.watch('sender_id') &&
                  !methods.watch('org_vendor_id') && (
                    <p className="text-sm text-red-400  ml-1">
                      You will not get any usage insights.
                    </p>
                  )}

                {methods.watch('org_vendor_id') && (
                  <p className="text-sm text-muted-foreground  ml-1">
                    You will be tracking{' '}
                    <span className="font-bold text-primary">usage</span> from
                    {' ' +
                      orgVendors?.data?.find(
                        (vendor) =>
                          vendor.id ===
                          Number(methods.getValues('org_vendor_id'))
                      )?.root_domain}
                  </p>
                )}

                {methods.watch('org_vendor_id') &&
                  !methods.watch('sender_id') && (
                    <p className="text-sm text-red-400  ml-1">
                      No costs will be tracked.
                    </p>
                  )}
              </Column>

              <Row className="gap-2">
                {(methods.watch('org_vendor_id') ||
                  methods.watch('sender_id')) && (
                  <Button
                    variant="secondary"
                    className="ml-auto"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      methods.watch('org_vendor_id')
                        ? autofillFromExtension()
                        : autofillFromReceipts()
                    }}
                  >
                    <Pencil className="mr-2 h-4 w-4" />
                    Autofill
                  </Button>
                )}
              </Row>

              {(methods.watch('sender_id') ||
                methods.watch('org_vendor_id')) && (
                <>
                  <FormInput
                    label="Name"
                    name="name"
                    methods={methods}
                    className="w-full"
                    type="text"
                  />

                  <FormTextarea
                    label="Description"
                    name="description"
                    methods={methods}
                    className="w-full"
                  />

                  <FormSelect
                    name="department"
                    label="Department"
                    options={departmentOptions}
                    methods={methods}
                  />
                  <FormSelect
                    name="type"
                    label="Type"
                    options={[
                      {
                        value: 'software',
                        label: 'Software',
                      },
                      {
                        value: 'service',
                        label: 'Service',
                      },
                      {
                        value: 'other',
                        label: 'Other',
                      },
                    ]}
                    methods={methods}
                  />

                  {/* <FormInput
                    name="website"
                    label="Website"
                    methods={methods}
                    className="w-full"
                    type="text"
                  /> */}

                  <Column className=" rounded-md p-3 border">
                    <FormCheckbox
                      name="is_tracking"
                      label="Track usage with chrome extension"
                      methods={methods}
                    />

                    {methods.watch('is_tracking') && (
                      <FormInput
                        name="root_domain"
                        label="URL to track"
                        methods={methods}
                        className="w-full"
                        type="text"
                        description={`All domains containing ${methods.watch(
                          'root_domain'
                        )} will be tracked for usage. For example: ${methods.watch(
                          'root_domain'
                        )}, ${methods.watch('root_domain')}/dashboard, etc.`}
                      />
                    )}
                  </Column>
                </>
              )}

              <Button
                onClick={handleSubmit}
                className="ml-auto w-fit mt-2"
                disabled={
                  !methods.watch('sender_id') && !methods.watch('org_vendor_id')
                }
              >
                Track {methods.watch('sender_id') && 'costs'}
                {methods.watch('sender_id') &&
                  methods.watch('org_vendor_id') &&
                  ' & usage'}
                {!methods.watch('sender_id') &&
                  methods.watch('org_vendor_id') &&
                  'usage'}
              </Button>
            </Column>
          </ScrollArea>
        </FormWrapper>
      </DialogContent>
    </Dialog>
  )
}
