import { Chrome } from 'lucide-react'
import { Column } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'

export function ExtensionPage() {
  return (
    <Column
      className="gap-4 w-full h-[100vh] items-center justify-center"
      style={{
        backgroundImage:
          'url(https://images.unsplash.com/photo-1557682250-33bd709cbe85?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
        backgroundRepeat: 'repeat',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        backgroundBlendMode: 'lighten',
      }}
    >
      <Card className="glass-thick">
        <h2 className="text-lg ">You have been invited!</h2>
        <p className="text-sm  text-muted-foreground">
          Click the button below to install the PinnOne extension.
        </p>

        <Button
          className="mt-4"
          variant="link"
          onClick={() => {
            window.open(
              'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
              '_blank'
            )
          }}
        >
          <Chrome className="mr-2 w-4 h-4" />
          Download Extension
        </Button>
      </Card>
    </Column>
  )
}
