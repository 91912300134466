import { Blocks, MousePointerClick, TriangleAlert, Wallet } from 'lucide-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { useUntrackedTools } from 'src/api/db/helpers/useUntrackedTools'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'

import { SpendByCategory } from '../../components/SpendByCategory'
import { StatCard } from '../../components/StatCard'
import MonthCalendar from './MonthCalendar'

export const Overview = () => {
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams()

  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({
    organization_id: orgId || '',
  })
  const untrackedTools = useUntrackedTools(orgId || '')

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-4">
        <StatCard
          title="New detected software"
          value={untrackedTools}
          icon={
            <TriangleAlert
              className={`w-6 h-6 ${
                untrackedTools > 0 ? 'text-orange-500' : 'text-muted-foreground'
              }`}
            />
          }
          description="Click to review"
          onClick={() => navigate(`${paths.software_untracked}`)}
        />

        <StatCard
          title="Seat utilization"
          value={`$${tools?.calculated?.totalAnnualWasteSeatUtilization || 0}`}
          icon={<Wallet className="w-6 h-6 text-muted-foreground" />}
          description="Potential savings"
          color={
            tools?.calculated?.totalAnnualWasteSeatUtilization! > 0
              ? 'green'
              : null
          }
          onClick={() => setSearchParams({ tab: 'cost-reduction' })}
        />

        <StatCard
          title="Low usage"
          value={`$${tools?.calculated?.totalAnnualWasteLowUsage || 0}`}
          icon={<Wallet className="w-6 h-6 text-muted-foreground" />}
          description="Potential savings"
          color={
            tools?.calculated?.totalAnnualWasteLowUsage! > 0 ? 'green' : null
          }
          onClick={() => setSearchParams({ tab: 'cost-reduction' })}
        />
      </Row>

      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-3">
          <MonthCalendar />
        </div>

        <div className="col-span-2 gap-4">
          <Column className="gap-4">
            <Row className="gap-4">
              <StatCard
                title="Tracked tools"
                value={`${tools?.tools?.filter(
                  (tool) => tool.status === 'in_stack'
                ).length}`}
                icon={<Blocks className="w-6 h-6 text-muted-foreground" />}
                description="Last 30 days"
                onClick={() => navigate(paths.software_table)}
              />

              <StatCard
                title="Active users"
                value={tools?.calculated?.totalActiveUsers || 0}
                icon={
                  <MousePointerClick className="w-6 h-6 text-muted-foreground" />
                }
                description="Last 30 days"
              />
            </Row>

            <Row className="gap-4">
              <SpendByCategory />
            </Row>
          </Column>
        </div>
      </div>
    </Column>
  )
}
